import { Injectable } from "@angular/core";
import { ConnectionService } from "./connection.service";

@Injectable({
  providedIn: "root"
})
export class SourceTokenService {
  constructor(private connectionService: ConnectionService) {}

  async get() {
    try {
      return await this.connectionService.post("yelpTokens/get");
    } catch (e) {
      return [];
    }
  }

  async remove(yelpToken: any) {
    return await this.connectionService.post("yelpTokens/remove", yelpToken);
  }

  async update(yelpToken: any) {
    return await this.connectionService.post("yelpTokens/update", yelpToken);
  }
}
