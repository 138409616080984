import { Component } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { filter, Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { updateOriginData } from "../../../client-static/src/helpers/utility";

@UntilDestroy()
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  private dialogSub: Subscription;

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private googleTagManagerService: GoogleTagManagerService,
    private activatedRoute: ActivatedRoute
  ) {
    // @ts-ignore: private option not yet exposed for public use
    router.canceledNavigationResolution = "computed";
    if (navigator.userAgent.indexOf("Edg/") != -1) {
      document.body.classList.add("edge-browser");
    }
  }

  ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(untilDestroyed(this))
      .subscribe((item: NavigationEnd) => {
        const gtmTag = {
          event: "page",
          pageName: item.url
        };
        this.googleTagManagerService.pushTag(gtmTag);
      });

    // Capture referrer if it exists
    if (document.referrer) {
      updateOriginData({ referrer: document.referrer }, true);
    }

    const keysToMaintain = ["source_token_id"];
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(async params => {
      if (Object.keys(params).length > 0) {
        const newQueryParams = {};

        keysToMaintain.forEach(keyToMaintain => {
          newQueryParams[keyToMaintain] = params[keyToMaintain];
        });

        if (Object.keys(newQueryParams).length !== Object.keys(params).length) {
          updateOriginData(params);

          await this.router.navigate([], {
            queryParams: newQueryParams
          });
        }
      }
    });
    // set origin from cookie
    const value = `; ${document.cookie}`;
    const parts = value.split(`; origin=`);
    let originCookie = "";
    if (parts.length === 2) originCookie = parts.pop()?.split(";").shift();
    if (originCookie) {
      localStorage.setItem("origin", originCookie);
    }

    this.signUpForDialogChanges();
  }

  private signUpForDialogChanges() {
    this.dialogSub = this.matDialog.afterOpened.pipe(untilDestroyed(this)).subscribe(() => {
      document.body.classList.add("dialogOpen");
    });

    this.dialogSub.add(
      this.matDialog.afterAllClosed.pipe(untilDestroyed(this)).subscribe(() => {
        document.body.classList.remove("dialogOpen");
      })
    );
  }
}
