<!--<div class="main-content">-->
<!--  <h2>What to do when a customer sends a phone number?</h2>-->
<!--</div>-->

<div class="main-content">
  <mat-slide-toggle *ngIf="!isSystemSettings" [checked]="inputConfig" (click)="toggleGlobalSettings()">
    {{ operationConfigComponent.globalConfigText }}
  </mat-slide-toggle>

  <h4>When phone number received:</h4>

  <mat-checkbox
    [ngModel]="config?.send"
    (ngModelChange)="config.send = $event; config.sendOnLaterMessages = $event || config?.sendOnEmail"
    [disabled]="isGlobalConfigApplied"
    color="primary"
    >Send message to customer
  </mat-checkbox>

  <mat-checkbox
    [ngModel]="config?.phoneTriggerIntegrations"
    (ngModelChange)="config.phoneTriggerIntegrations = $event"
    [disabled]="isGlobalConfigApplied"
    color="primary"
    >Trigger Integrations
  </mat-checkbox>

  <h4>When Email received:</h4>

  <mat-checkbox
    [ngModel]="config?.sendOnEmail"
    (ngModelChange)="config.sendOnEmail = $event; config.sendOnLaterMessages = $event || config.send"
    [disabled]="isGlobalConfigApplied"
    color="primary">
    Send message to customer
  </mat-checkbox>

  <mat-checkbox
    [ngModel]="config?.emailTriggerIntegrations"
    (ngModelChange)="config.emailTriggerIntegrations = $event"
    [disabled]="isGlobalConfigApplied"
    color="primary"
    >Trigger Integrations
  </mat-checkbox>

  <h4>Customer Confirmation Message:</h4>

  <div>
    <mat-checkbox
      [ngModel]="config?.sendOnLaterMessages"
      (ngModelChange)="config.sendOnLaterMessages = $event"
      [disabled]="isGlobalConfigApplied || (!config.send && !config.sendOnEmail!)"
      color="primary">
      Always send (even mid-conversation)
    </mat-checkbox>
    <span
      class="question-mark-tooltip"
      [class.disabled]="isGlobalConfigApplied || (!config.send && !config.sendOnEmail!)"
      matTooltipClass="question-mark-tooltip-panel"
      [matTooltipDisabled]="isGlobalConfigApplied || (!config.send && !config.sendOnEmail!)"
      matTooltip="Always send confirmation, even if contact details were shared during an ongoing conversation. If unchecked, confirmation only sent when business hasn't previously messaged the customer."
      >?</span
    >
  </div>

  <mat-form-field>
    <mat-label> Message to send during business hours: (Configured on Yelp)</mat-label>
    <textarea
      cdkTextareaAutosize
      matInput
      [ngModel]="config?.text"
      (ngModelChange)="config?.text !== undefined && (config.text = $event)"
      [disabled]="isGlobalConfigApplied || (!config.send && !config.sendOnEmail!)"></textarea>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Message to send during business OFF hours: (Configured on Yelp)</mat-label>
    <textarea
      cdkTextareaAutosize
      matInput
      [ngModel]="config?.textBusinessOffHours"
      (ngModelChange)="config.textBusinessOffHours = $event"
      [disabled]="isGlobalConfigApplied || (!config.send && !config.sendOnEmail!)"></textarea>
  </mat-form-field>
</div>
