import { Component, Input, Optional } from "@angular/core";
import {
  YelpConfigFollowUp,
  YelpConfigFollowUpPredefinedMessages,
  YelpTimeUnit
} from "../../../../../../server/src/db/classes-helpers/yelpConfig";
import { OperationConfigBaseComponent } from "../operation-config-base.component";
import { OperationConfigComponent } from "../operation-config.component";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: "app-operation-config-follow-ups",
  templateUrl: "./operation-config-follow-ups.component.html",
  styleUrl: "./operation-config-follow-ups.component.scss"
})
export class OperationConfigFollowUpsComponent extends OperationConfigBaseComponent<YelpConfigFollowUp> {
  @Input() override inputConfig: YelpConfigFollowUp | null;
  @Input() override inputGlobalConfig: YelpConfigFollowUp;
  @Input() isGlobalConfig: boolean = true;
  @Input() isAllowedMultipleMessages: boolean = true;

  constructor(@Optional() public override operationConfigComponent: OperationConfigComponent) {
    super(operationConfigComponent);
  }

  protected override notifyConfigChange(): void {
    this.operationConfigComponent?.followUpsConfigChaged(this.config, this.isGlobalConfigApplied);
  }

  public addMessage(): void {
    const message: YelpConfigFollowUpPredefinedMessages = {
      text: "",
      minutes: 60,
      displayValue: 60,
      type: YelpTimeUnit.Minutes
    };

    this.config.predefinedMessages.push(message);
  }

  public removeMessage(message: YelpConfigFollowUpPredefinedMessages) {
    const idx = this.config.predefinedMessages.indexOf(message);
    this.config.predefinedMessages.splice(idx, 1);
  }

  public drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.config.predefinedMessages, event.previousIndex, event.currentIndex);
  }

  public updateMinutes(event, message: YelpConfigFollowUpPredefinedMessages): void {
    message.minutes = event.minutes;
    message.type = event.type;
    this.notifyConfigChange();
  }
}
