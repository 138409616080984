<div class="sms-wrapper">
  <div *ngIf="integration?.phone?.length" class="sms-wrapper__header">
    <h3>Verified phone numbers</h3>

    <mat-slide-toggle
      class="example-margin"
      color="primary"
      (change)="toggleEnable($event.checked)"
      [(ngModel)]="integration.enabled"
      [disabled]="!integration?.phone?.length">
      <strong>Enabled</strong>
    </mat-slide-toggle>
  </div>

  <mat-accordion>
    <mat-expansion-panel *ngFor="let phone of integration?.phone; let i = index" [expanded]="false">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ i + 1 }}.&nbsp;&nbsp;{{ phone.number }} </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-action-row *ngIf="!confirmDeleteIndex.includes(i)">
        <button mat-icon-button color="warn" (click)="confirmDelete(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-action-row>
      <mat-action-row *ngIf="confirmDeleteIndex.includes(i)">
        <span class="confirm">Are you sure?</span>
        <button mat-button color="primary" (click)="deletePhoneNumber(i)">Yes</button>
        <button mat-button (click)="cancelDelete(i)">No</button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>

  <h3 style="margin-top: 20px">Add new phone number</h3>
  <p>When you add a new number, the bot will automatically send a notification to all verified numbers</p>
  <small>To add a phone number, you need to proceed with code phone verification</small>

  <div *ngIf="!isVerifying">
    <mat-form-field appearance="fill">
      <mat-label>Enter new phone number</mat-label>
      <input matInput [(ngModel)]="newPhone.number" />
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="startVerification()" [disabled]="isLodaing">Send code</button>
  </div>
  <div *ngIf="isVerifying">
    <mat-form-field appearance="fill">
      <mat-label>Enter code</mat-label>
      <input matInput [(ngModel)]="otpCode" />
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="verifyCode()" [disabled]="isLodaing">Verify</button>
  </div>
</div>
