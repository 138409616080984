import { Component, Input, OnInit } from "@angular/core";
import {
  WelcomeMessageAiConfig,
  WelcomeMessageAiConfigAnswerSize
} from "../../../../../../server/src/db/classes-helpers/yelpConfig";
import { staticConfig } from "../../../../../../server/config/static-config";

const { maxLenCanDo, maxLenBusinessDescription } = staticConfig.ai;

@Component({
  selector: "app-operation-config-welcome-message-ai",
  templateUrl: "./operation-config-welcome-message-ai.component.html",
  styleUrl: "./operation-config-welcome-message-ai.component.scss"
})
export class OperationConfigWelcomeMessageComponentAi implements OnInit {
  @Input() inputConfig: WelcomeMessageAiConfig;
  @Input() isGlobalConfigApplied: boolean;
  public maxLenCanDo = maxLenCanDo;
  public maxLenBusinessDescription = maxLenBusinessDescription;
  public answerSizeOptions: { name: string; value: number }[];

  ngOnInit() {
    this.initializeAnswerSizeOptions();

    if (this.inputConfig?.answerSize === undefined) {
      this.inputConfig.answerSize = WelcomeMessageAiConfigAnswerSize.Short;
    }
  }

  initializeAnswerSizeOptions() {
    this.answerSizeOptions = Object.entries(WelcomeMessageAiConfigAnswerSize)
      .filter(([key]) => isNaN(Number(key)))
      .map(([key, value]) => ({ name: this.capitalizeFirstLetter(key), value: value as number }));

    if (
      this.inputConfig?.answerSize !== undefined &&
      !Object.values(WelcomeMessageAiConfigAnswerSize).includes(this.inputConfig.answerSize)
    ) {
      this.answerSizeOptions.push({
        name: "Custom",
        value: this.inputConfig.answerSize
      });
    }
  }

  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  isCustomValue(): boolean {
    return (
      this.inputConfig?.answerSize !== undefined &&
      !Object.values(WelcomeMessageAiConfigAnswerSize).includes(this.inputConfig.answerSize)
    );
  }

  onAnswerSizeChange(event: any) {
    if (typeof event.value === "number") {
      this.inputConfig.answerSize = event.value;
    }
  }
}
