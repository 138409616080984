import { Component } from "@angular/core";
import { SessionService } from "../../Services/session.service";

@Component({
  selector: "app-checkout-completed",
  standalone: true,
  template: ""
})
export class CheckoutCompletedComponent {
  constructor(private sessionService: SessionService) {}
}
