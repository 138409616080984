import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";

import { FeatureCard } from "./feature-card/feature-card.component";
import { Question } from "./question/question.component";
import { TestimonialsComponent } from "./testimonials/testimonials.component";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { GooglePopupLoginComponent } from "./google-popup-login/google-popup-login.component";
import { ImageDialogComponent } from "./feature-card/image-dialog.component";
import { MatDialogActions, MatDialogClose, MatDialogContent } from "@angular/material/dialog";

@NgModule({
  declarations: [FeatureCard, Question, TestimonialsComponent, GooglePopupLoginComponent, ImageDialogComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogContent,
    MatDialogClose
  ],
  exports: [FeatureCard, Question, TestimonialsComponent, GooglePopupLoginComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HomeComponentsModule {}
