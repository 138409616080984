import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { BehaviorSubject, Observable } from "rxjs";

import { SuperAdminService } from "src/app/Services/super-admin.service";

export interface MinimalUser {
  _id: string;
  email: string;
  name?: string;
  forceTrial?: boolean;
}

@Component({
  selector: "app-all-user-picker",
  templateUrl: "./all-user-picker.component.html",
  styleUrl: "./all-user-picker.component.scss"
})
export class AllUserPickerComponent {
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  @Input() placeholder = "Select user";
  @Input() isVisible = true;

  @Output() userSelected = new EventEmitter<MinimalUser>();

  public isLoading = false;
  public userControl = new FormControl();
  public filteredUsers: Observable<MinimalUser[]>;
  public selectedUser: MinimalUser | null = null;
  public filledTimezones = 0;
  public minutes = 60;
  public whatsappMessage = "";

  private usersSubject = new BehaviorSubject<MinimalUser[]>([]);
  private users: MinimalUser[] = [];

  constructor(private superAdminService: SuperAdminService) {
    this.filteredUsers = this.usersSubject.asObservable();
  }

  onFormFieldClicked(): void {
    if (this.users.length === 0) {
      this.loadUsers();
    } else {
      this.openAutocomplete();
    }
  }

  public refreshUsers(): void {
    this.users = [];
    this.userControl.setValue("");
  }

  private loadUsers(): void {
    this.isLoading = true;
    this.superAdminService.getAllUserWithId().then(
      ({ allUsers }) => {
        this.users = allUsers;
        this.usersSubject.next(allUsers);
        this.isLoading = false;
        this.openAutocomplete();
        this.filterUsers(this.userControl.value);
      },
      error => {
        console.error("Failed to fetch users:", error);
        this.isLoading = false;
      }
    );
  }

  private openAutocomplete(): void {
    setTimeout(() => {
      this.autocompleteTrigger.openPanel();
    });
  }

  public filterUsers(value: string): void {
    if (!value) {
      this.usersSubject.next(this.users);
      return;
    }

    const filterValue = value.toLowerCase();
    const filteredUsers = this.users.filter(user => user.email.toLowerCase().includes(filterValue));
    this.usersSubject.next(filteredUsers);
  }

  public async onUserSelected(event: any): Promise<void> {
    this.selectedUser = event.option.value;

    this.userSelected.emit(this.selectedUser);
  }

  public displayUser(user: MinimalUser): string {
    return user && user.email ? user.email : "";
  }
}
