import { Injectable } from "@angular/core";
import { ConnectionService } from "./connection.service";
import { GoHighLevelIntegration } from "../../../../server/src/db/classes/notification-integration.type";
import { OperationConfigType } from "../../../../server/src/db/classes/operation-config.type";

@Injectable({
  providedIn: "root"
})
export class GoHighLevelIntegrationService {
  constructor(private connectionService: ConnectionService) {}

  async manageAccessToken(id: string, type: OperationConfigType, accessToken: string): Promise<GoHighLevelIntegration> {
    return await this.connectionService.post("/operation-config/integration/goHighLevelIntegration/manageAccessToken", {
      id,
      type,
      accessToken
    });
  }
}
