import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren, signal } from "@angular/core";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatExpansionPanel } from "@angular/material/expansion";
import { UserService } from "src/app/Services/user.service";

@Component({
  selector: "app-user-settings-dialog",
  templateUrl: "./user-settings-dialog.component.html",
  styleUrls: ["./user-settings-dialog.component.scss"]
})
export class UserSettingsDialogComponent implements OnInit {
  @ViewChildren(MatExpansionPanel) panels: QueryList<MatExpansionPanel>;
  @ViewChildren("messageTextarea") messageTextareas: ElementRef[];
  settingsForm: FormGroup;
  isLoading = false;
  filteredMessages: { index: number; title: string; text: string }[] = [];
  private currentFilter: string = "";
  readonly panelOpenState = signal(false);
  chips = [
    {
      display: "Customer Display Name",
      value: "#customer_display_name#"
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      predefinedMessages?: string;
    },
    private fb: FormBuilder,
    private userService: UserService,
    public dialogRef: MatDialogRef<UserSettingsDialogComponent>
  ) {
    this.settingsForm = this.fb.group({
      predefinedMessages: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.loadUserSettings();
  }

  updateMessageDisplayName(index: number): void {
    const messageGroup = this.predefinedMessages.at(index) as FormGroup;
    const title = messageGroup.get("title").value;
    const text = messageGroup.get("text").value;
    const newDisplayName = this.getMessageDisplayName({ title, text });

    if (this.filteredMessages[index] && this.filteredMessages[index].title !== newDisplayName) {
      this.filteredMessages[index].title = newDisplayName;
    }
  }

  get predefinedMessages(): FormArray {
    return this.settingsForm.get("predefinedMessages") as FormArray;
  }

  async loadUserSettings(): Promise<void> {
    this.isLoading = true;
    try {
      const settings = await this.userService.getUserSettings();
      if (settings && settings.predefinedMessages) {
        settings.predefinedMessages.forEach(message => {
          const messageGroup = this.createMessageFormGroup(message);
          this.predefinedMessages.push(messageGroup);
          this.setupTitleListener(messageGroup, this.predefinedMessages.length - 1);
        });
      }
      this.updateFilteredMessages();

      if (this.data && this.data.predefinedMessages) {
        this.addPredefinedMessage(this.data.predefinedMessages);
      }
    } catch (error) {
      console.error("Error loading user settings:", error);
    } finally {
      this.isLoading = false;
    }
  }

  createMessageFormGroup(message?: { title?: string; text: string }): FormGroup {
    return this.fb.group({
      title: [message?.title || ""],
      text: [message?.text || "", Validators.required]
    });
  }

  setupTitleListener(messageGroup: FormGroup, index: number): void {
    messageGroup.valueChanges.subscribe(() => {
      this.updateMessageDisplayName(index);
    });
  }

  addMessage(): void {
    const newMessage = this.createMessageFormGroup();
    this.predefinedMessages.insert(0, newMessage);
    this.setupTitleListener(newMessage, 0);
    this.updateFilteredMessages();
    this.expandNewMessage();
  }

  addPredefinedMessage(text: string): void {
    const newMessage = this.createMessageFormGroup({ text });
    this.predefinedMessages.insert(0, newMessage);
    this.setupTitleListener(newMessage, 0);
    this.updateFilteredMessages();
    this.expandNewMessage();
  }

  expandNewMessage(): void {
    setTimeout(() => {
      this.panels.first.open();
    });
  }

  removeMessage(index: number): void {
    this.predefinedMessages.removeAt(index);
    this.updateFilteredMessages();
  }

  async saveSettings(): Promise<void> {
    if (this.settingsForm.valid) {
      this.isLoading = true;
      try {
        const predefinedMessages = this.predefinedMessages.value.map(message => ({
          title: message.title || undefined,
          text: message.text
        }));
        const updatedUser = await this.userService.updateUserSettings(predefinedMessages);
        this.dialogRef.close(updatedUser);
      } catch (error) {
        console.error("Error updating user settings:", error);
      } finally {
        this.isLoading = false;
      }
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  async selectMessage(index: number): Promise<void> {
    const selectedMessage = this.predefinedMessages.at(index).value;
    await this.saveSettings();
    this.dialogRef.close(selectedMessage.text);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    if (filterValue !== this.currentFilter) {
      this.currentFilter = filterValue;
      this.updateFilteredMessages(filterValue);
    }
  }

  updateFilteredMessages(filterValue: string = ""): void {
    this.filteredMessages = this.predefinedMessages.controls
      .map((control, index) => ({
        index,
        title: this.getMessageDisplayName(control.value),
        text: control.get("text").value
      }))
      .filter(
        message => message.title.toLowerCase().includes(filterValue) || message.text.toLowerCase().includes(filterValue)
      );
  }

  insertChipTag(placeholder: string, i: number): void {
    const textarea = (this.messageTextareas as any)._results[i].nativeElement as HTMLTextAreaElement;
    const currentText = this.predefinedMessages.at(i).get("text").value;

    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    const newText = currentText.slice(0, start) + placeholder + currentText.slice(end);

    this.predefinedMessages.at(i).get("text").setValue(newText);
  }

  getMessageDisplayName(message: { title: string; text: string }): string {
    return message.title || message.text.slice(0, 30) + (message.text.length > 30 ? "..." : "");
  }
}
