import { NotificationIntegrations } from "../classes/notification-integration.type";

export type TimePeriod = {
  start: string;
  end: string;
};

export type DailySchedule = TimePeriod[];

export type WeekDays = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "AnyDay";

export type WeekDaySchedule = {
  [day in WeekDays]?: DailySchedule;
};

export type WeekDayConfig = WeekDaySchedule & {
  initialResponseGracePeriod?: number;
};

export interface YelpConfigWelcomePredefinedMessages {
  text: string;
  preventFollowUps: boolean;
  words?: string[];
}

export interface YelpConfigWelcomeMessage {
  predefinedMessages: YelpConfigWelcomePredefinedMessages[];
  isAiEnabled?: boolean;
  aiConfig?: WelcomeMessageAiConfig;
  isDelay?: boolean;
  delayConfig?: DelayConfig;
}

interface DelayConfig {
  minutes: number;
  type: string;
}

export enum WelcomeMessageAiConfigAnswerSize {
  Short = 30,
  Long = 50,
  Detailed = 80
}

export interface WelcomeMessageAiConfig {
  businessDescription: string;
  canDo: string;
  cantDo: string;
  answerSize?: WelcomeMessageAiConfigAnswerSize;
}

export enum YelpTimeUnit {
  Minutes = "Minutes",
  Hours = "Hours",
  Days = "Days"
}

export interface YelpConfigFollowUpPredefinedMessages {
  text: string;
  minutes: number;
  type?: YelpTimeUnit;
  displayValue?: number;
}

export interface YelpConfigFollowUp {
  predefinedMessages: YelpConfigFollowUpPredefinedMessages[];
  continueFollowUpsIfBizInteracted?: boolean;
}

export type YelpConfigMessage = YelpConfigWelcomeMessage | YelpConfigFollowUp;
export type YelpConfigMessages = YelpConfigMessage[];
export type YelpConfigNotifyCustomer = {
  send: boolean;
  phoneTriggerIntegrations: boolean;
  sendOnEmail: boolean;
  emailTriggerIntegrations: boolean;
  sendOnLaterMessages: boolean;
  text: string;
  textBusinessOffHours?: string;
};
export type YelpConfigNotifyBusiness = {
  sendWhatsapp: boolean;
  addBusinessNameToMessage: boolean;
};

export enum YelpDayOfWeek {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday"
}

export interface YelpTimeRange {
  startTime: string; // Format: "HH:MM" in 24-hour format
  endTime: string; // Format: "HH:MM" in 24-hour format
}

export interface YelpDaySchedule {
  day: YelpDayOfWeek;
  enabled: boolean;
  timeRanges: YelpTimeRange[];
}

export interface YelpConfigSchedule {
  scheduleType: "anyDay" | "specificDays";
  anyDayTimeRanges?: YelpTimeRange[];
  daySchedules: YelpDaySchedule[];
  timeZone: string;
}

export interface YelpConfig {
  welcomeMessage?: YelpConfigWelcomeMessage;
  followUps?: YelpConfigFollowUp;
  sendCustomerMessageOnPhoneFound?: YelpConfigNotifyCustomer;
  botOffHours?: WeekDaySchedule;
  followUpHours?: WeekDayConfig;
  timeZone?: string;
  notificationIntegrations?: NotificationIntegrations;
  continueFollowUpsIfBizInteracted?: boolean;
}
