import { Injectable } from "@angular/core";
import { ConnectionService } from "./connection.service";
import { YelpConfig } from "../../../../server/src/db/classes-helpers/yelpConfig";
import { ConfigGroup } from "../../../../server/src/db/classes-helpers/config";

@Injectable({
  providedIn: "root"
})
export class YelpConfigService {
  constructor(private connectionService: ConnectionService) {}

  async get(locationId: string): Promise<ConfigGroup> {
    try {
      return await this.connectionService.post("operation-config/getLocationConfig", {
        locationId
      });
    } catch (e) {
      console.error("Error Fetching Config", e);
      throw "Error Fetching Config";
    }
  }

  async getByBusinessId(businessId: string): Promise<ConfigGroup> {
    try {
      return await this.connectionService.post("operation-config/getByBusinessId", {
        businessId
      });
    } catch (e) {
      console.error("Error Fetching Config", e);
      throw "Error Fetching Config";
    }
  }

  async getSystemConfig(): Promise<ConfigGroup> {
    try {
      return await this.connectionService.post("operation-config/getSystemConfig");
    } catch (e) {
      console.error("Error Fetching Config", e);
      throw "Error Fetching Config";
    }
  }

  async updateByBusiness(yelpConfig: YelpConfig, businessId: string) {
    return await this.connectionService.post("operation-config/updateByBusiness", {
      yelpConfig,
      businessId
    });
  }

  async updateBySource(yelpConfig: YelpConfig, locationId: string) {
    return await this.connectionService.post("operation-config/updateBySource", {
      yelpConfig,
      locationId
    });
  }

  async updateSystemConfig(yelpConfig: YelpConfig) {
    return await this.connectionService.post("operation-config/updateSystemConfig", {
      yelpConfig
    });
  }
}
