import { Injectable } from "@angular/core";
import { ConnectionService } from "./connection.service";
import { Business } from "../../../../server/src/db/classes/business";
import { SessionService } from "./session.service";
import { StringConstants } from "../../../../server/src/helpers/string-constants";

interface SubscriptionStatus {
  total: number;
  error: number;
  working: number;
}

@Injectable({
  providedIn: "root"
})
export class BusinessService {
  static readonly ALL_BUSINESSES_NODE_ID = StringConstants.AllBusinessesId;
  readonly ALL_BUSINESSES_NODE_ID = BusinessService.ALL_BUSINESSES_NODE_ID;

  constructor(
    private connectionService: ConnectionService,
    private sessionService: SessionService
  ) {}

  hasSubscription = (business: Business | null, includeChildren: boolean = false): SubscriptionStatus => {
    const status: SubscriptionStatus = { total: 0, error: 0, working: 0 };

    if (!business) {
      return status;
    }

    // Calculate status for current business
    status.total = business.sources.length;
    business.sources.forEach(source => {
      if (source.enabled) {
        const isError =
          source.billingPaused ||
          source.billingWarn ||
          (this.sessionService.session.role === "superAdmin" && source.error);

        if (isError) {
          status.error++;
        } else {
          status.working++;
        }
      }
    });

    if (!includeChildren) {
      business.children?.forEach(child => {
        const childStatus = this.hasSubscription(child, includeChildren);
        status.total += childStatus.total;
        status.error += childStatus.error;
        status.working += childStatus.working;
      });
    }

    return status;
  };

  async get() {
    return await this.connectionService.post("business/get");
  }

  async update(business: Partial<Business>) {
    await this.connectionService.post("business/update", business);
  }

  async delete(business) {
    await this.connectionService.post("business/delete", business);
  }

  filterRealBusiness = businesses => {
    return businesses.filter((business: Business) => {
      return business._id.toString() !== this.ALL_BUSINESSES_NODE_ID;
    });
  };
}
