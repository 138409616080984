import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Source } from "../../../../../server/src/db/classes/source";
import { SessionService } from "../../Services/session.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Business } from "../../../../../server/src/db/classes/business";
import { DataService } from "../../Services/data.service";

export interface DialogData {
  location: Source;
  tokens: any[];
  allBusinessesFlat: (Business | any)[];
  allBusinessesFlatById: { [key: string]: Business | any };
}

@UntilDestroy()
@Component({
  selector: "source-token-refresh-results-dialog",
  templateUrl: "./source-token-refresh-results-dialog.component.html",
  styleUrls: ["./source-token-refresh-results-dialog.component.scss"]
})
export class SourceTokenRefreshResultsDialogComponent {
  results = [];

  map = {
    added: { text: "Newly Added", color: "greenColor" },
    refreshed: { text: "Updated", color: "greenColor" },
    transferred: { text: "Transferred to this Account", color: "greenColor" },
    notAllowedTransferred: {
      text: "Already Attached to a different Source Token",
      color: "redColor"
    },
    notTransferredBilling: {
      text: "No active billing found on this account, Cant transfer active source.",
      color: "redColor"
    }
  };

  constructor(
    public sessionService: SessionService,
    public dataService: DataService,
    public dialogRef: MatDialogRef<SourceTokenRefreshResultsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.data = this.dataService.allTokensHashedById$.value[this.data._id.toString()];

    Object.keys(this.data.lastRefreshResults).forEach(key => {
      const value = this.data.lastRefreshResults[key];
      value.forEach(result => {
        this.results.push({ status: key, ...result });
      });
    });
  }
}
