import { Component } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-text-divider",
  templateUrl: "./text-divider.component.html",
  styleUrls: ["./text-divider.component.scss"]
})
export class TextDividerComponent {}
