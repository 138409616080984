<div class="main-content">
  <div *ngIf="isGlobalConfig">
    <mat-slide-toggle *ngIf="!isSystemSettings" [checked]="inputConfig" (click)="toggleGlobalSettings()">
      {{ operationConfigComponent.globalConfigText }}
    </mat-slide-toggle>
  </div>

  <div class="alert-message">
    <p>Follow-up timing begins when welcome message is sent. Every follow-up is timed from the previous message.</p>
  </div>

  <div class="setting-wrapper">
    <div class="action-bar">
      <button
        [class.visibilityHidden]="isGlobalConfigApplied"
        class="add-icon"
        *ngIf="isAllowedMultipleMessages"
        (click)="addMessage()"
        mat-mini-fab
        color="primary">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
      <div
        *ngFor="let message of config.predefinedMessages"
        cdkDrag
        [cdkDragDisabled]="!isAllowedMultipleMessages"
        class="follow-up-message-container flexRowParent">
        <div class="card-actions flexItem" *ngIf="isAllowedMultipleMessages">
          <button
            [class.visibilityHidden]="isGlobalConfigApplied || !isAllowedMultipleMessages"
            (click)="removeMessage(message)"
            class="delete-icon"
            mat-mini-fab
            color="primary">
            <mat-icon>delete</mat-icon>
          </button>
          <svg
            [class.displayNone]="isGlobalConfigApplied"
            class="drag-handle"
            cdkDragHandle
            width="24px"
            fill="currentColor"
            viewBox="0 0 24 24">
            <path
              d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
        <mat-card class="follow-up-message flexItemBiggest">
          <app-time-input
            [minutes]="message.minutes || 1"
            [timeUnit]="message.type || 'Minutes'"
            [isGlobalConfigApplied]="isGlobalConfigApplied"
            (timeConfigChanged)="updateMinutes($event, message)"></app-time-input>
          <mat-form-field class="message-input">
            <mat-label>Message</mat-label>
            <textarea
              cdkTextareaAutosize
              matInput
              minlength="2"
              required
              [disabled]="isGlobalConfigApplied"
              [(ngModel)]="message.text">
            </textarea>
          </mat-form-field>
        </mat-card>
      </div>
    </div>
  </div>
</div>
