import { DestroyRef, Directive, inject, Optional, Self } from "@angular/core";
import { NgModel } from "@angular/forms";
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Directive({
  selector: "[nzInputDebounce]"
})
export class NzInputDebounceDirective {
  destroyRef = inject(DestroyRef);
  private _debouncedUpdateSubject = new Subject();
  debouncedUpdate$ = this._debouncedUpdateSubject
    .asObservable()
    .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(400), distinctUntilChanged());
  constructor(@Optional() @Self() private ngModel: NgModel) {}

  ngOnInit() {
    if (!this.ngModel) {
      return;
    }

    this.ngModel.viewToModelUpdate = newValue => {
      this.ngModel.viewModel = newValue;
      this._debouncedUpdateSubject.next(newValue);
    };

    this.debouncedUpdate$.pipe(untilDestroyed(this)).subscribe(newValue => {
      this.ngModel.update.emit(newValue);
    });
  }
}
