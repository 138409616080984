import { CanActivateFn, Router } from "@angular/router";
import { inject } from "@angular/core";
import { SessionService } from "../Services/session.service";
import { StorageService } from "../Services/storage.service";
import { ClientSession } from "../../../../server/src/db/classes-helpers/clientSession";
import { CookieService } from "../Services/cookie.service";

export const MainGuard: CanActivateFn = async (route, state) => {
  const sessionService = inject(SessionService);
  const router = inject(Router);

  const loginInfo = CookieService.getCookie("login");
  if (loginInfo) {
    const loginSession = JSON.parse(loginInfo) as ClientSession;
    if (loginSession.token) {
      StorageService.setItem("session", loginSession);
      StorageService.setItem("sessionId", loginSession.token);
    }
  }
  if (sessionService.session) {
    return true;
  }
  router.navigateByUrl("/login");
  return false;
};
