import { Component, HostListener } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { Sort } from "@angular/material/sort";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { firstValueFrom, from, fromEvent, Observable, of } from "rxjs";

import { MapDialogComponent } from "../../dialogs/map-dialog/map-dialog.component";
import { LeadsService } from "../../Services/leads.service";
import { FirestoreDocumentListener } from "../../Services/specific-ids-firestore.class";
import { LeadMessagesDialogComponent } from "../../dialogs/lead-messages-dialog/lead-messages-dialog.component";
import { MenuComponent } from "../../components/menu/menu.component";
import { StorageService } from "../../Services/storage.service";
import { SessionService } from "../../Services/session.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { cloneDeep } from "lodash";
import { DataService } from "../../Services/data.service";
import { StringConstants } from "../../../../../server/src/helpers/string-constants";
import { ConfirmDialogComponent } from "../../dialogs/confirm-dialog/confirm-dialog.component";
import { NzDialogService } from "../../utils/services/nz-dialog.service";
import { Lead } from "../../../../../server/src/db/classes/lead";
import { HttpErrorResponse } from "@angular/common/http";
import { catchError, map, mergeMap, toArray } from "rxjs/operators";
import { LeadStatus, LeadStatusValues } from "../../../../../server/src/db/classes/lead.types";
import { ScheduledMessagesDialogComponent } from "../../dialogs/scheduled-messages-follow-ups-dialog/scheduled-messages-dialog.component";
import yelp from "!!raw-loader!../../../assets/yelp-vector.svg";
import thumbtack from "!!raw-loader!../../../assets/thumbtack-vector.svg";

@UntilDestroy()
@Component({
  selector: "app-messages",
  templateUrl: "./leads.component.html",
  styleUrls: ["./leads.component.scss", "./compact-view.leads.scss"]
})
export class LeadsComponent {
  businessSources = [];
  openingLead = false;
  leads: Lead[] = [];
  pageSize = 25;
  ready = false;
  dataReady = false;
  displayedColumns = [
    'source',
    "name",
    "customerName",
    "phoneNumber",
    "lastMessage",
    "address",
    "lastMemo",
    "lastInteraction",
    "status"
  ];
  fetchingData = false;
  moreDataToFetch = false;
  selectedSources: {
    _id: string;
  } = StorageService.getItem("filterSources") || {
    _id: StringConstants.AllBusinessesId,
    name: "All Sources"
  };
  ignoreNextPush = 0;
  currentUserId;
  hasContactInfo = false;

  audio = new Audio();

  sort: Sort = { active: "lastInteraction", direction: "asc" };

  filterStatus = StorageService.getItem("filterStatus") || [];
  customerName = "";

  fetchCounter = 0;
  sourcesHashed = {};

  firestoreDocumentListener: FirestoreDocumentListener;
  private dialogRef: MatDialogRef<LeadMessagesDialogComponent, any>;

  constructor(
    private matDialog: MatDialog,
    public menuComponent: MenuComponent,
    private dataService: DataService,
    private firestoreDB: AngularFirestore,
    private nzDialogService: NzDialogService,
    public sessionService: SessionService,
    public leadsService: LeadsService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    this.currentUserId = this.sessionService.session.id;
    this.onResize();
    this.audio.src = "../../assets/notify.mp3";
    // this.audio.muted = true
    this.audio.load();
    this.firestoreDocumentListener = new FirestoreDocumentListener(this.firestoreDB, "leadsUpdateByLocations");
    this.firestoreDocumentListener.updates$.pipe(untilDestroyed(this)).subscribe(async event => {
      if (this.ignoreNextPush === 0) {
        await this.fetchDataForTable();
        if (event.newCustomerMessages) {
          try {
            //await this.audio.play();
          } catch (e) {
            console.log("sound didnt play");
          }
        }
      } else {
        this.ignoreNextPush--;
      }
    });

    this.prepare();

    iconRegistry.addSvgIconLiteral("yelp", sanitizer.bypassSecurityTrustHtml(yelp));
    iconRegistry.addSvgIconLiteral("thumbtack", sanitizer.bypassSecurityTrustHtml(thumbtack));
  }

  private prepare = async () => {
    this.dataService.currentSourcesList$.pipe(untilDestroyed(this)).subscribe(businessSources => {
      if (businessSources !== null) {
        this.businessSources = cloneDeep(businessSources);
        this.sourcesHashed = {};
        this.dataService.allSources$.value.forEach(l => (this.sourcesHashed[l._id] = l));

        this.updateFirestore();

        this.ready = true;

        setTimeout(() => {
          this.subscribeToScroll();
          this.fetchDataForTable();
        });
      }
    });
  };

  @HostListener("window:resize", ["$event"])
  onResize() {
    setTimeout(() => {
      this.checkIfScrollNeeded();
    });
  }

  fetchDataForTable = async ({ getMore = false } = {}) => {
    this.dataReady = false;
    const isTableScrollTop = this.getTableElement()?.scrollTop === 0;

    this.fetchingData = true;
    this.firestoreDocumentListener.setReady(false);

    const sources = this.getSelectedSources();

    const fetchCounter = ++this.fetchCounter;

    const requestedSkip = getMore ? this.leads.length : 0;
    const requestedLimit =
      requestedSkip === 0 && !isTableScrollTop ? this.leads.length || this.pageSize : this.pageSize;

    const queryParams: any = {
      sources,
      sort: {
        [this.sort.active === "lastInteraction" ? "messages.time_created" : this.sort.active]:
          this.sort.direction === "desc" ? 1 : -1
      },
      hasContactInfo: this.hasContactInfo,
      limit: requestedLimit,
      skip: requestedSkip
    };

    queryParams.filterName = this.customerName;
    if (this.filterStatus.length === 0) {
      queryParams.statuses = this.leadsService.statuses.map(x => x.value);
    } else {
      queryParams.statuses = this.filterStatus;
    }

    const newLeads = await this.leadsService.get(queryParams);

    if (fetchCounter !== this.fetchCounter) {
      return;
    }
    this.moreDataToFetch = newLeads.length === requestedLimit;

    if (!getMore) {
      this.leads = [];
      try {
        this.getTableElement().scrollTop = 0;
      } catch (e) {}
    }

    // newLeads = newLeads.filter(newLead => !this.leads.find(existingLead => existingLead._id === newLead._id))

    newLeads.forEach((newLead, newLeadIndex) => {
      const oldLeadIndex = this.leads.findIndex(lead => lead._id === newLead._id);
      if (oldLeadIndex === -1) {
        this.leads.splice(newLeadIndex + (getMore ? this.leads.length : 0), 0, newLead);
      } else {
        this.leads[oldLeadIndex] = newLead;
      }
    });
    if (this.dialogRef) {
      const updatedLeadCurrentlyOpen = newLeads.find(
        lead => lead._id === this.dialogRef.componentInstance.data.lead._id
      );
      if (updatedLeadCurrentlyOpen) {
        this.dialogRef.componentInstance.data.lead = updatedLeadCurrentlyOpen;
        this.dialogRef.componentInstance.markAsRead();
        this.dialogRef.componentInstance.scrollToBottom && this.dialogRef.componentInstance.scrollToBottom();
      }
    }

    this.firestoreDocumentListener.setReady(true);

    this.fetchingData = false;
    this.leads = [...this.leads];
    setTimeout(() => {
      this.checkIfScrollNeeded();
    });
    this.dataReady = true;
  };

  onTableScroll = async (element: any) => {
    if (!element) {
      return;
    }
    const tableViewHeight = element.offsetHeight;
    const tableScrollHeight = element.scrollHeight;
    const scrollLocation = element.scrollTop;

    const buffer = 100;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit && this.moreDataToFetch && !this.fetchingData) {
      await this.fetchDataForTable({ getMore: true });
    }
  };

  private subscribeToScroll() {
    const element = this.getTableElement();
    const scroll$ = fromEvent(element, "scroll");

    scroll$.pipe(untilDestroyed(this)).subscribe(() => {
      this.onTableScroll(element);
    });
  }

  private checkIfScrollNeeded() {
    this.onTableScroll(this.getTableElement());
  }

  statusColors: Record<LeadStatusValues, string> = {
    noAnswer: "red",
    followUp: "orange",
    autoFollowUp: "yellow",
    scheduledMessage: "purple",
    answered: "green",
    meeting: "blue",
    done: "white"
  };

  // sortData($event: Sort) {
  //     this.sort = $event;
  //     this.fetchDataForTable();
  // }
  //

  private checkImageUrlStatus(url: string): Observable<boolean> {
    const controller = new AbortController();
    return from(fetch(url, { signal: controller.signal })).pipe(
      map(_response => {
        controller.abort();

        return _response.ok;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0 || error.status === 200) {
          // CORS error or successful response
          return of(true);
        }

        // Any other error
        return of(false);
      })
    );
  }

  public hasInaccessibleImages(lead: { messages: any[] }): Observable<boolean> {
    const imageUrls = lead.messages
      .filter(message => message.event_type === "ATTACHMENT_GROUPING")
      .flatMap(message => message.event_content?.attachments || [])
      .filter(attachment => attachment.mime_type.startsWith("image/"))
      .map(attachment => attachment.url);

    if (imageUrls.length === 0) {
      return of(false);
    }

    return from(imageUrls).pipe(
      mergeMap(url => this.checkImageUrlStatus(url)),
      toArray(),
      map(results => {
        return results.some(result => result === false);
      })
    );
  }

  async openMessages(index: number, isMemo?) {
    if (this.openingLead) {
      return;
    }
    this.openingLead = true;
    let lead: Lead = this.leads[index];
    try {
      const hasInaccessibleImages = await firstValueFrom(this.hasInaccessibleImages(lead));
      if (hasInaccessibleImages) {
        const newLead = await this.leadsService.refreshExpiredImageMessages(lead.location_id, lead.lead_id);
        this.leads[index] = {
          ...this.leads[index],
          messages: newLead.messages
        };
        this.leads = [...this.leads];
        lead = this.leads[index];
      }
    } catch (e) {
    } finally {
      this.openingLead = false;
    }

    this.dialogRef = this.matDialog.open(LeadMessagesDialogComponent, {
      maxWidth: "1000px",
      width: "100%",
      closeOnNavigation: false,
      data: {
        isMemo,
        lead,
        leadsComponent: this,
        location: this.sourcesHashed[lead.yelpLocationId$.toString()]
      }
    });
    this.dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.dialogRef = undefined;
      });
  }

  locationSelectionChanged = ($event: any) => {
    this.selectedSources = $event;
    StorageService.setItem("filterSources", $event);
    this.updateFirestore();
    this.fetchDataForTable();
  };

  statusChanged = (lead: Lead, $event: MatSelectChange) => {
    if ($event.value === LeadStatus.ScheduledMessage) {
      this.openScheduledMessageDialog(lead);

      $event.source.value = lead.status;

      return;
    }

    lead.status = $event.value;

    if (this.filterStatus.length > 0 && !this.filterStatus.includes($event.value)) {
      const idx = this.leads.indexOf(lead);
      if (idx > -1) {
        this.leads.splice(idx, 1);
      }
      this.leads = [...this.leads];
    }

    this.leadsService.update(lead);
    this.ignoreNextPush++;
  };

  filterStatusChanged = ($event: MatSelectChange) => {
    StorageService.setItem("filterStatus", $event.value);
    this.filterStatus = $event.value;
    this.fetchDataForTable();
  };

  openMap(lead) {
    this.matDialog.open(MapDialogComponent, {
      maxWidth: "800px",
      width: "90%",
      height: "90%",
      closeOnNavigation: false,
      data: {
        lead
      }
    });
  }

  openScheduledMessageDialog(lead: Lead) {
    this.matDialog.open(ScheduledMessagesDialogComponent, {
      maxWidth: "1000px",
      height: "80%",
      width: "100%",
      closeOnNavigation: false,
      data: {
        lead
      }
    });
  }

  filterCustomerNameChanged($event: any) {
    StorageService.setItem("customerName", $event);
    this.fetchDataForTable();
  }

  ngOnDestroy() {
    this.firestoreDocumentListener?.destroy();
  }

  private getSelectedSources() {
    if (this.selectedSources) {
      if (this.selectedSources._id === StringConstants.AllBusinessesId) {
        return this.businessSources.map(s => s._id);
      } else {
        return [this.selectedSources._id];
      }
    }

    return [];
  }

  private updateFirestore() {
    const sources = this.getSelectedSources();
    this.firestoreDocumentListener.setIds(sources.length === 0 ? Object.keys(this.sourcesHashed) : sources);
  }

  private getTableElement() {
    return document.querySelector(".leads-table-container");
  }

  isUnread(row) {
    if (row.readBy) {
      return !row.readBy?.includes?.(this.currentUserId);
    }
    return false;
  }

  leadIdClicked(lead) {
    this.nzDialogService.open(ConfirmDialogComponent, {
      width: "100%",
      backdropClass: "CLASSA",
      panelClass: "CLASSB",
      closeOnNavigation: false,
      data: {
        title: "LEAD",
        mainText: JSON.stringify(lead, null, 2),
        hideConfirm: true,
        CancelText: "Ok"
      }
    });
  }

  phoneCheckboxClicked = async () => {
    if (this.hasContactInfo) {
      this.hasContactInfo = null;
    } else {
      this.hasContactInfo = this.hasContactInfo !== null;
    }
    setTimeout(async () => {
      await this.fetchDataForTable();
    });
  };
}
