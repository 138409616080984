import { cloneDeep } from "lodash";
import { Component } from "@angular/core";
import { SourceService } from "../../Services/source.service";
import { SourceTokenService } from "../../Services/source-token.service";
import { MatDialog } from "@angular/material/dialog";
import { SessionService } from "../../Services/session.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SourcesToken } from "../../../../../server/src/db/classes/sources-token";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { DataService } from "../../Services/data.service";
import { SourceTokenRefreshResultsDialogComponent } from "../../dialogs/source-token-refresh-results-dialog/source-token-refresh-results-dialog.component";
import { SourceDialogComponent } from "../../dialogs/source-dialog/source-dialog.component";
import ArrayHelpers from "../../../../../server/src/helpers/array-helpers";
import { StringConstants } from "../../../../../server/src/helpers/string-constants";
import { SourceTokenDialogComponent } from "../../dialogs/source-token-add-dialog/source-token-dialog.component";
import { SourceDialogService } from "../../Services/source-dialog.service";

@UntilDestroy()
@Component({
  selector: "source-token",
  templateUrl: "./source-token.component.html",
  styleUrls: ["./source-token.component.scss"]
})
export class SourceTokenComponent {
  sourceTokens: SourcesToken[] | any[] = [];
  ready = false;

  constructor(
    private sourceService: SourceService,
    private sourceDialogService: SourceDialogService,
    private yelpTokenService: SourceTokenService,
    public sessionService: SessionService,
    private _snackBar: MatSnackBar,
    public dataService: DataService,
    private dialog: MatDialog
  ) {
    dataService.currentBusiness$.pipe(untilDestroyed(this)).subscribe(business => {
      if (this.ready) {
        setTimeout(() => {
          this.refresh();
        });
      }
    });
    this.refresh();
  }

  addYelpLocation = async () => {
    this.sourceDialogService.openInviteDialog();
  };

  showLastRefreshResult = async (yelpToken: SourcesToken) => {
    const dialogRef = this.dialog.open(SourceTokenRefreshResultsDialogComponent, {
      maxWidth: "500px",
      width: "100%",
      backdropClass: "CLASSA",
      panelClass: "CLASSB",
      closeOnNavigation: false,
      data: yelpToken
    });
  };

  refreshLocations = async yelpToken => {
    this._snackBar.open("Updating all Token's Sources please wait...", null, {
      panelClass: ["success-snackbar"],
      verticalPosition: "bottom",
      horizontalPosition: "center"
    });
    await this.sourceService.refreshLocationWithToken(yelpToken);
    this._snackBar.open("Success!, refreshing list..", null, {
      panelClass: ["success-snackbar"],
      verticalPosition: "bottom",
      horizontalPosition: "center",
      duration: 3000
    });
    await this.dataService.updateBusinessAndSources();
    setTimeout(async () => {
      await this.showLastRefreshResult(yelpToken);
    });
  };

  private refresh = async () => {
    this.sourceTokens = cloneDeep(this.dataService.allTokens$.value);
    const currentBiz = this.dataService.currentBusiness$.value;
    if (currentBiz._id !== StringConstants.AllBusinessesId) {
      this.sourceTokens = this.sourceTokens.filter(st => st.businessId$ === currentBiz._id);
    }
    this.sourceTokens.forEach((t: any) => {
      t.locations = this.dataService.currentSourcesList$.value.filter(s => s.yelpTokenId$ === t._id);
    });
    this.ready = true;
  };

  remove = async (yelpToken: any) => {
    let text = "ARE YOU SURE YOU WANT TO DELETE THIS ACCOUNT AND ALL ITS LOCATIONS??!!??!?!";
    if (confirm(text) == true) {
      await this.yelpTokenService.remove(yelpToken);
      this.refresh();
    }
  };

  // editMessagingSettings(yelpToken: SourcesToken) {
  //   const dialogRef = this.dialog.open(LocationConfigDialogComponent, {
  //     maxWidth: '800px',
  //     width: '100%',
  //     height: '80%',
  //     backdropClass: 'CLASSA',
  //     panelClass: 'CLASSB',
  //     closeOnNavigation: false,
  //     data: {
  //       location: cloneDeep(location)
  //     }
  //   });
  //
  //   dialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe(async (yelpConfig: YelpConfig) => {
  //     if (yelpConfig) {
  //       await this.yelpConfigService.update(yelpConfig);
  //     }
  //     this.refresh();
  //   });
  // }
  editToken(yelpToken: any) {
    const dialogRef = this.dialog.open(SourceTokenDialogComponent, {
      maxWidth: "100%",
      width: "500px",
      backdropClass: "CLASSA",
      panelClass: "CLASSB",
      closeOnNavigation: false,
      data: cloneDeep(yelpToken)
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(async data => {
        if (data) {
          await this.dataService.updateBusinessAndSources();
        }
      });
  }

  editLocation = source => {
    const dialogRef = this.dialog.open(SourceDialogComponent, {
      maxWidth: "500px",
      width: "100%",
      height: "500px",
      backdropClass: "CLASSA",
      panelClass: "CLASSB",
      closeOnNavigation: false,
      data: {
        source: cloneDeep(source),
        tokens: this.sourceTokens
      }
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(async location => {
        if (location) {
          await this.dataService.updateBusinessAndSources();
        }
      });
  };
  sortSources = arr => {
    return arr.sort(ArrayHelpers.SortAlphabetically(["-enabled", "name"]));
  };
}
