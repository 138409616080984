export const environment = {
  production: false,
  server: "https://main-xcnlxj3rqq-uc.a.run.app",
  staticSiteUrl: "https://staging.nzleads.com",
  firebase: {
    apiKey: "AIzaSyDHNfWF_87-WJs2d5jzztRROnZq7lqmuIQ",
    authDomain: "staging-c9011.firebaseapp.com",
    projectId: "staging-c9011",
    storageBucket: "staging-c9011.appspot.com",
    messagingSenderId: "699686597986",
    appId: "1:699686597986:web:819d1a2b3bd86a6b99e83b"
  }
};
