import { Component, NgZone } from "@angular/core";
import { LoginService } from "../../../Services/login.service";
import { Router } from "@angular/router";
import { MainLoginComponent } from "../main-login.component";
import { StorageService } from "../../../Services/storage.service";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent {
  email = "";
  pass = "";
  showPassword = false;
  url;
  errors;

  constructor(
    private loginService: LoginService,
    private ngZone: NgZone,
    public mainLoginComponent: MainLoginComponent,
    private router: Router
  ) {
    this.email = StorageService.getItem("email") || "";
  }

  login = async () => {
    this.errors = undefined;
    try {
      this.mainLoginComponent.isAuthInProgress = true;
      StorageService.setItem("email", this.email);
      await this.loginService.login({
        email: this.email.toLowerCase(),
        pass: this.pass
      });
    } catch (e) {
      if (e.parseErrors) {
        return (this.errors = e.parseErrors);
      }
      this.mainLoginComponent.loginError = e?.error?.error || e?.error || e;
    } finally {
      this.mainLoginComponent.isAuthInProgress = false;
    }
  };

  signUp() {
    this.router.navigateByUrl("/login/signup");
  }

  emailChanged($event: any) {
    StorageService.setItem("email", $event);
  }

  forgotPassword() {
    this.router.navigateByUrl("/login/forgot");
  }
}
