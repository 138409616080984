<div class="time-input-container">
  <mat-form-field class="time-value">
    <mat-label>Time to wait</mat-label>
    <input
      type="number"
      matInput
      min="1"
      [max]="max"
      [(ngModel)]="displayValue"
      (ngModelChange)="editTime()"
      [disabled]="isGlobalConfigApplied" />
  </mat-form-field>
  <mat-form-field class="time-unit">
    <mat-label>Unit</mat-label>
    <mat-select [(ngModel)]="timeUnit" (ngModelChange)="editTime()" [disabled]="isGlobalConfigApplied">
      <mat-option *ngFor="let type of timeUnitTypes" [value]="type">
        {{ yelpTimeUnit[type] }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
