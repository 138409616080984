import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Source } from "../../../../../server/src/db/classes/source";
import { SessionService } from "../../Services/session.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Business } from "../../../../../server/src/db/classes/business";
import { DataService } from "../../Services/data.service";
import { SourceTokenService } from "../../Services/source-token.service";
import { BusinessService } from "../../Services/business.service";

export interface DialogData {
  location: Source;
  tokens: any[];
  allBusinessesFlat: (Business | any)[];
  allBusinessesFlatById: { [key: string]: Business | any };
}

@UntilDestroy()
@Component({
  selector: "source-token-refresh-results-dialog",
  templateUrl: "./source-token-dialog.component.html",
  styleUrls: ["./source-token-dialog.component.scss"]
})
export class SourceTokenDialogComponent {
  constructor(
    public sessionService: SessionService,
    public dataService: DataService,
    public businessService: BusinessService,
    private sourceTokenService: SourceTokenService,
    public dialogRef: MatDialogRef<SourceTokenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async save() {
    await this.sourceTokenService.update(this.data);
    this.dialogRef.close(this.data);
  }
}
