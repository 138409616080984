import { Injectable } from "@angular/core";
import { ConnectionService } from "./connection.service";
import { NotificationIntegrationType } from "../../../../server/src/db/classes/notification-integration.type";
import { OperationConfigType } from "../../../../server/src/db/classes/operation-config.type";

@Injectable({
  providedIn: "root"
})
export class IntegrationService {
  constructor(private connectionService: ConnectionService) {}

  async toggleEnable(
    id: string,
    integrationType: NotificationIntegrationType,
    operationConfigType: OperationConfigType,
    newEnabledStatus: boolean
  ): Promise<boolean> {
    return await this.connectionService.post("/operation-config/integration/toggle", {
      id,
      integrationType,
      operationConfigType,
      newEnabledStatus
    });
  }

  async removeAccessToken(id: string, type: NotificationIntegrationType): Promise<boolean> {
    return await this.connectionService.post("/operation-config/integration/removeAccessToken", {
      id,
      type
    });
  }

  async removeErrors(id: string, type: NotificationIntegrationType): Promise<boolean> {
    return await this.connectionService.post("/operation-config/integration/resetError", {
      id,
      type
    });
  }

  async generateCodeByOperationConfigId(
    operationConfigId: string,
    type: OperationConfigType
  ): Promise<{ code: string }> {
    return await this.connectionService.post("/yelpLocations/generateWhatsappByOperationConfigId", {
      operationConfigId,
      type
    });
  }
}
