import { BehaviorSubject } from "rxjs";
import { StorageService } from "./storage.service";
import { BusinessService } from "./business.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Business } from "../../../../server/src/db/classes/business";
import { Injectable } from "@angular/core";
import { Source } from "../../../../server/src/db/classes/source";
import ArrayHelpers from "../../../../server/src/helpers/array-helpers";
import ObjectHelpers from "../../../../server/src/helpers/object-helpers";
import { StringConstants } from "../../../../server/src/helpers/string-constants";
import { AccountPermissions } from "../../../../server/src/db/classes-helpers/account-permissions";
import { SessionService } from "./session.service";
import { SourcesToken } from "../../../../server/src/db/classes/sources-token";
import { SourceDialogService } from "./source-dialog.service";

@UntilDestroy()
@Injectable({
  providedIn: "root"
})
export class DataService {
  allBusinessesTree$ = new BehaviorSubject<any>(null);
  allBusinessesList$ = new BehaviorSubject<any>(null);
  allBusinessesHashedById$ = new BehaviorSubject<any>(null);

  allSources$ = new BehaviorSubject<any>(null);
  allSourcesHashedById$ = new BehaviorSubject<any>(null);

  allTokens$ = new BehaviorSubject<any>(null);
  allTokensHashedById$ = new BehaviorSubject<any>(null);

  currentBusiness$ = new BehaviorSubject<any>(
    StorageService.getItem("currentBiz") || {
      name: "All Businesses",
      _id: StringConstants.AllBusinessesId
    }
  );

  currentBusinessesList$ = new BehaviorSubject<any>(null);
  currentBusinessesIds$ = new BehaviorSubject<any>(null);
  currentSourcesList$ = new BehaviorSubject<any>(null);

  constructor(
    private sourceDialogService: SourceDialogService,
    private sessionService: SessionService,
    private businessService: BusinessService
  ) {}

  private createDataTree = dataset => {
    const hashTable = Object.create(null);
    dataset.forEach(aData => (hashTable[aData._id] = { ...aData, children: [] }));
    const dataTree = [];
    dataset.forEach(aData => {
      if (aData._id === StringConstants.AllBusinessesId) {
        dataTree.push(hashTable[aData._id]);
      } else if (!hashTable[aData.parent]) {
        hashTable[StringConstants.AllBusinessesId].children.push(hashTable[aData._id]);
      } else {
        hashTable[aData.parent].children.push(hashTable[aData._id]);
      }
    });
    return dataTree;
  };

  updateBusinessAndSources = async () => {
    const businesses = await this.businessService.get();
    let tokens: SourcesToken[] = [];
    businesses.forEach(b => {
      b.ownRole = AccountPermissions.FindSingleFirstPermForBusinessInList(
        b._id,
        businesses,
        this.sessionService.session
      );
      tokens = [...tokens, ...b.tokens];
    });
    this.allTokens$.next(Object.freeze(tokens));
    const allTokensHashedById$ = {};
    tokens.forEach(t => {
      allTokensHashedById$[t._id?.toString()] = t;
    });
    this.allTokensHashedById$.next(allTokensHashedById$);
    this.allBusinessesList$.next(businesses);
    const businessesTree = this.createDataTree(businesses);

    ObjectHelpers.sortNodesAndChildren(businessesTree);
    this.allBusinessesTree$.next(Object.freeze(businessesTree));
    this.allBusinessesHashedById$.next(Object.freeze(this.extractBusinesses(businessesTree)));
    const sourcesFlatById = this.extractSources(businessesTree);
    const sources: (Source | any)[] = Object.values(sourcesFlatById);
    sources.forEach(s => {
      s.ownRole = AccountPermissions.FindUserPermissionForSource(
        s,
        sources,
        businesses,
        this.sessionService.session as any
      );
    });
    this.allSourcesHashedById$.next(Object.freeze(sourcesFlatById));
    this.allSources$.next(Object.freeze(sources.sort(ArrayHelpers.SortAlphabetically())));
    await this.updateCurrentBusiness();
  };

  private extractBusinesses(businesses: (Business | any)[], businessById = {}) {
    businesses.forEach(business => {
      if (business._id) {
        businessById[business._id] = business;
      }
      if (business.children) {
        this.extractBusinesses(business.children, businessById);
      }
    });
    return businessById;
  }

  private extractSources(businesses: (Business | any)[], sourcesById = {}) {
    businesses.forEach(business => {
      business.sources?.forEach(source => {
        sourcesById[source._id] = source;
      });

      if (business.children) {
        this.extractSources(business.children, sourcesById);
      }
    });
    return sourcesById;
  }

  public updateCurrentBusiness = async () => {
    this.currentBusiness$.next(this.allBusinessesHashedById$.value[this.currentBusiness$.value._id]);

    if (!this.currentBusiness$.value) {
      this.currentBusiness$.next(this.allBusinessesHashedById$.value[StringConstants.AllBusinessesId]);
    }

    const newBusinessList = this.findChildrenIds(
      Object.values(this.allBusinessesHashedById$.value),
      this.currentBusiness$.value._id
    );

    this.currentBusinessesList$.next(Object.freeze(newBusinessList));
    this.currentBusinessesIds$.next(Object.freeze(this.currentBusinessesList$.value.map(x => x._id)));

    const currentSourcesList = this.allSources$.value?.filter((source: Source) => {
      return (
        this.currentBusinessesIds$.value.includes(source.businessId$) ||
        (this.currentBusiness$.value._id === StringConstants.AllBusinessesId &&
          !this.allSourcesHashedById$.value[source.businessId$.toString()])
      );
    });
    this.currentSourcesList$.next(Object.freeze(currentSourcesList));
    setTimeout(async () => {
      await this.openAddTokenDialogIfNoSources();
    });
  };

  private findChildrenIds(businesses, targetId) {
    let result = [];

    function search(data) {
      for (const item of data) {
        // If the current item has children, recursively search them
        if (item.children && item.children.length > 0) {
          search(item.children);
        }

        if (item._id === targetId) {
          // Found the target ID, add it to the result array
          result.push(item);

          // Add the IDs of all children and grandchildren
          result = result.concat(getDescendantIds(item));
        }
      }
    }

    function getDescendantIds(item) {
      let ids = [];
      for (const child of item.children) {
        ids.push(child);
        if (child.children && child.children.length > 0) {
          ids = ids.concat(getDescendantIds(child));
        }
      }
      return ids;
    }

    search(businesses);
    return result;
  }

  openAddTokenDialogIfNoSources = async () => {
    if (this.allSources$.value.length === 0) {
      await this.sourceDialogService.openInviteDialog(0);
    }
  };

  async selectBusiness(business: Business) {
    this.currentBusiness$.next(business);
    await this.updateCurrentBusiness();
    StorageService.setItem("currentBiz", business);
  }
}
