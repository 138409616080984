import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { ComponentType } from "@angular/cdk/overlay";

@Injectable({
  providedIn: "root"
})
export class NzDialogService {
  readonly DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
    width: "100%",
    closeOnNavigation: false
  };

  constructor(private matDialog: MatDialog) {}

  open<T, D = any, R = any>(component: ComponentType<T>, config?: MatDialogConfig<D>): MatDialogRef<T, R> {
    return this.matDialog.open(component, {
      ...this.DEFAULT_DIALOG_CONFIG,
      ...config
    });
  }
}
