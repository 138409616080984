<div
  *ngIf="ready"
  class="flexColumnParent"
  [class.mobileQuery]="menuComponent.mobileQuery"
  [class.smallMobileQuery]="menuComponent.smallMobileQuery">
  <div class="flexItemBiggest leads-table-container">
    <div class="table-container">
      <table
        mat-table
        [dataSource]="leads"
        matSort
        [matSortDisabled]="true"
        matSortActive="lastInteraction"
        matSortDirection="asc"
        [matSortDisableClear]="true">
        <ng-container matColumnDef="source">
          <th mat-header-cell *matHeaderCellDef style="min-width: 50px; text-align: center">Source</th>
          <td mat-cell *matCellDef="let lead">
            <div class="content" [ngSwitch]="lead.sourceType">
              <mat-icon *ngSwitchCase="'YELP'" svgIcon="yelp"></mat-icon>
              <mat-icon *ngSwitchCase="'THUMBTACK'" svgIcon="thumbtack"></mat-icon>
              <div *ngSwitchDefault>-</div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef style="max-width: 200px">
            <form class="example-form">
              <app-location-search
                [allowAll]="true"
                [selectedSources]="selectedSources"
                (changed)="locationSelectionChanged($event)"
                [sources]="businessSources">
              </app-location-search>
            </form>
          </th>
          <td mat-cell *matCellDef="let lead">
            <div class="content">{{ sourcesHashed[lead.yelpLocationId$]?.name }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="lastInteraction">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="lastInteraction" style="width: 160px">
            Last Interaction
          </th>
          <td mat-cell *matCellDef="let lead">
            <div class="content">
              {{
                lead.messages[lead.messages.length - 1].time_created
                  | dateTimeFromIso
                  | dateTimeToLocal
                  | dateTimeToFormat: "M/d/yyyy, hh:mm:a"
              }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="lastMemo">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px">Memos</th>
          <td mat-cell *matCellDef="let lead; let i = index" (click)="openMessages(i, true)" class="linkHandler">
            <div class="content link">{{ (lead.memos | lastTextMessage)?.event_content?.text || "Add memo" }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="lastMessage">
          <th mat-header-cell *matHeaderCellDef style="min-width: 120px">Messages</th>
          <td mat-cell *matCellDef="let lead; let i = index" (click)="openMessages(i)" class="linkHandler">
            <div class="content">
              <div class="link">
                <mat-icon
                  *ngIf="sessionService.session.role === 'superAdmin' && lead.ai"
                  style="font-size: 14px; height: 14px; width: 14px; color: #4caf50"
                  >smart_toy
                </mat-icon>
                {{ (lead.messages | lastTextMessage)?.event_content.text }}
              </div>

              <div class="last-message-sub">
                {{
                  lead.messages[lead.messages.length - 1].time_created
                    | dateTimeFromIso
                    | dateTimeToLocal
                    | dateTimeToFormat: "M/d/yyyy, hh:mm:a"
                }}
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="customerName">
          <th mat-header-cell *matHeaderCellDef style="min-width: 125px">
            <mat-form-field floatLabel="always">
              <mat-label>Customer Name</mat-label>
              <input
                matInput
                [(ngModel)]="customerName"
                (ngModelChange)="filterCustomerNameChanged($event)"
                nzInputDebounce />
            </mat-form-field>
          </th>
          <td
            mat-cell
            *matCellDef="let lead"
            (click)="sessionService.session.role === 'superAdmin' && leadIdClicked(lead)">
            <div class="content" [class.link]="sessionService.session.role === 'superAdmin'">
              {{ lead.messages[0]?.user_display_name }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="phoneNumber">
          <th mat-header-cell *matHeaderCellDef style="width: 150px">
            <mat-checkbox
              class="bold"
              (click)="phoneCheckboxClicked()"
              [ngModel]="hasContactInfo"
              [indeterminate]="hasContactInfo === null"
              >Contact Info
            </mat-checkbox>
          </th>
          <td
            mat-cell
            *matCellDef="let lead"
            [class.noPhone]="!lead.phoneNumber && !lead.email && !lead.maskedNumberExist">
            <span>
              <div *ngIf="lead.phoneNumber">
                <a class="content href link" href="tel:{{ lead.phoneNumber }}">{{ lead.phoneNumber | phonePipe }}</a>
              </div>
              <div *ngIf="!lead.phoneNumber && lead.maskedNumberExist">
                <a class="content href link pill" href="{{ lead.conversationLink }}">Temp Phone</a>
              </div>
              <div *ngIf="lead.email">
                <a class="content href link" href="mailto:{{ lead.email }}">{{ lead.email }}</a>
              </div>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef>Address</th>
          <td mat-cell *matCellDef="let lead" (click)="lead.address && openMap(lead)" class="linkHandler">
            <div class="content link">{{ lead.address }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="status" [stickyEnd]="true">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="always">
              <mat-label>Status</mat-label>
              <mat-select
                panelClass="status-dropdown"
                value="open"
                (selectionChange)="filterStatusChanged($event)"
                placeholder="All Statuses"
                [ngModel]="filterStatus"
                [multiple]="true">
                <mat-option *ngFor="let status of leadsService.statuses" [value]="status.value">
                  {{ status.text }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </th>

          <td mat-cell *matCellDef="let lead">
            <div class="content">
              <mat-form-field>
                <div class="flexRowParent status-option">
                  <div class="status-color flexItem" [ngClass]="statusColors[lead.status]"></div>
                  <mat-select
                    panelClass="status-dropdown"
                    value="open"
                    (selectionChange)="statusChanged(lead, $event)"
                    [ngModel]="lead.status">
                    <mat-option
                      *ngFor="let status of leadsService.statuses"
                      [value]="status.value"
                      [disabled]="status.value === 'autoFollowUp'">
                      <div class="flexRowParent status-option">
                        <div class="status-color flexItem" [ngClass]="statusColors[status.value]"></div>
                        <div class="flexItemBiggest">{{ status.text }}</div>
                      </div>
                    </mat-option>
                  </mat-select>
                </div>
              </mat-form-field>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [class.unread]="isUnread(row)"
          [class.mat-mdc-card]="menuComponent.mobileQuery"></tr>
        <ng-container *ngIf="leads?.length > 0">
          <tr [class.displayNone]="!dataReady" class="mat-row" *matNoDataRow>
            <td class="mat-cell empty-table-row" [attr.colspan]="displayedColumns.length">
              <ng-container *ngTemplateOutlet="noLeadsMessage"></ng-container>
            </td>
          </tr>
        </ng-container>
      </table>

      <div *ngIf="leads?.length === 0" [class.displayNone]="!dataReady">
        <p class="empty-table-row" [attr.colspan]="displayedColumns.length">
          <ng-container *ngTemplateOutlet="noLeadsMessage"></ng-container>
        </p>
      </div>
    </div>
  </div>
  <div class="flexItem table-loading" *ngIf="fetchingData">
    <div class="spinner-container">
      <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
  </div>
</div>

<ng-template #noLeadsMessage> New Leads from Activated Sources will appear here.</ng-template>
