import { SocialAuthService } from "@abacritt/angularx-social-login";
import { Component, HostListener } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";
import { LoginService } from "../../Services/login.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-main-login",
  templateUrl: "./main-login.component.html",
  styleUrls: ["./main-login.component.scss"]
})
export class MainLoginComponent {
  socialLoading = true;
  loginError;
  isAuthInProgress = false;

  constructor(
    private socialAuthService: SocialAuthService,
    private router: Router,
    private loginService: LoginService
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loginError = "";
      });
  }

  ngOnInit() {
    this.socialAuthService.authState.pipe(untilDestroyed(this)).subscribe(async socialUser => {
      this.isAuthInProgress = !!socialUser;
      if (socialUser) {
        try {
          await this.loginService.loginWithGoogle(socialUser);
        } catch (e) {
          this.loginError = e?.error?.error || e?.error || e;
          this.isAuthInProgress = false;
        }
      }
    });
  }

  @HostListener("document:keydown.tab", ["$event"])
  onKeydownHandler(event: KeyboardEvent) {
    if (this.isAuthInProgress) {
      event.preventDefault();
    }
  }
}
