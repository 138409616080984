import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";

@Pipe({
  standalone: true,
  name: "customDateTimeToRelative"
})
export class CustomDateTimeToRelativePipe implements PipeTransform {
  transform(value: DateTime | null): string {
    if (!value) {
      return "";
    }

    const now = DateTime.now();
    const diff = now.diff(value, ["months"]).months;

    if (diff < -11.5 && diff > -12) {
      return "in 1 year";
    }
    return value.toRelative() ?? "";
  }
}
