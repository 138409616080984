import { Injectable } from "@angular/core";
import { ConnectionService } from "./connection.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { ClientSession } from "../../../../server/src/db/classes-helpers/clientSession";
import { MinimalUser } from "../components/all-user-picker/all-user-picker.component";

@UntilDestroy()
@Injectable({
  providedIn: "root"
})
export class SuperAdminService {
  constructor(private connectionService: ConnectionService) {}

  async getAllUserWithId(): Promise<{ allUsers: MinimalUser[] }> {
    return await this.connectionService.post("superAdmin/getAllUsers", {});
  }

  async loginAs(selectedUserId: string): Promise<{ newSession: ClientSession }> {
    return await this.connectionService.post("superAdmin/loginAs", { selectedUserId });
  }
}
