import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { register } from "swiper/element/bundle";

import { USER_TESTIMONIALS } from "./testimonials";

interface TestimonialBusiness {
  name: string;
  userName: string;
  position: string;
  imgUrl: string;
  webSite: string;
}

export interface Testimonial {
  business: TestimonialBusiness;
  text: string;
  rating: 1 | 2 | 3 | 4 | 5;
}

@UntilDestroy()
@Component({
  selector: "app-testimonials",
  templateUrl: "./testimonials.component.html",
  styleUrls: ["./testimonials.component.scss"]
})
export class TestimonialsComponent implements AfterViewInit {
  @ViewChild("swiperContainer") swiperContainer: any;
  public testimonials: Testimonial[] = USER_TESTIMONIALS;

  constructor() {
    register();
  }

  ngAfterViewInit() {
    const swiperConfig = {
      slidesPerView: 1,
      spaceBetween: 10,
      pagination: {
        clickable: true
      },
      breakpoints: {
        "@0.00": {
          slidesPerView: 1
        },
        "@1.00": {
          slidesPerView: 2
        },
        "@1.25": {
          slidesPerView: 3
        }
      }
    };

    Object.assign(this.swiperContainer.nativeElement, swiperConfig);
    this.swiperContainer.nativeElement.initialize();
  }
}
