import { Inject, Injectable } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { HostURL } from "../../../../server/src/db/classes/hostURLs";
import { DOCUMENT } from "@angular/common";

@UntilDestroy()
@Injectable({
  providedIn: "root"
})
export class LogoService {
  host: HostURL;
  hostText;
  hostTextPrimary = "NZ Leads";
  phone = "+1 (818) 446-6122";
  email = "support@nzleads.com";

  hostTextMap: { [key in HostURL]: string } = {
    "https://nzleads.com": "NZ Leads"
  };

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.host = this.document.location.origin as HostURL;
    this.hostText = this.hostTextPrimary; // this.hostTextMap[this.host] || this.host.replace("http://", "").replace("https://", "");
  }
}
