import { Component, ViewChild } from "@angular/core";

import { SuperAdminService } from "src/app/Services/super-admin.service";
import { SUPER_ADMIN_SESSION_KEY, SessionService } from "src/app/Services/session.service";
import { StorageService } from "src/app/Services/storage.service";
import { LoginService } from "src/app/Services/login.service";
import { AllUserPickerComponent, MinimalUser } from "src/app/components/all-user-picker/all-user-picker.component";

@Component({
  selector: "app-login-as",
  templateUrl: "./login-as.component.html",
  styleUrls: ["./login-as.component.scss"]
})
export class LoginAsComponent {
  @ViewChild(AllUserPickerComponent) userPicker: AllUserPickerComponent;

  public selectedUser: MinimalUser | null = null;
  public isLoading = false;

  constructor(
    private superAdminService: SuperAdminService,
    public sessionService: SessionService,
    private loginService: LoginService
  ) {}

  public async onUserSelected(user: MinimalUser): Promise<void> {
    if (!user) {
      return;
    }

    this.selectedUser = user;
    const { newSession } = await this.superAdminService.loginAs(this.selectedUser._id);
    this.saveSuperAdminSession();
    this.sessionService.session = newSession;
    location.reload();
  }

  public logoutSuperAdmin(): void {
    if (!this.userPicker) {
      return;
    }

    this.selectedUser = null;

    this.userPicker.refreshUsers();

    this.loginService.logoutSession();
    this.restoreSuperAdminSession();
    location.reload();
  }

  private saveSuperAdminSession() {
    const currentSession = this.sessionService.session;
    if (currentSession) {
      StorageService.setItem(SUPER_ADMIN_SESSION_KEY, currentSession);
    }
  }

  private restoreSuperAdminSession() {
    const superAdminSession = this.sessionService.prevSessionSuperAdmin;
    if (superAdminSession) {
      this.sessionService.session = superAdminSession;
      StorageService.removeItem(SUPER_ADMIN_SESSION_KEY);
    }
  }

  public get hasSuperAdminRole(): boolean {
    const superAdminSession = this.sessionService.prevSessionSuperAdmin;
    return this.currentIsSuperAdmin || (superAdminSession && superAdminSession.role === "superAdmin");
  }

  public get currentIsSuperAdmin(): boolean {
    const currentSession = this.sessionService.session;
    return currentSession && currentSession.role === "superAdmin";
  }

  public displayUser(user: MinimalUser): string {
    return user && user.email ? user.email : "";
  }
}
