import { Component, Inject } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { DOCUMENT } from "@angular/common";
import { LogoService } from "../../Services/logo-service";

@UntilDestroy()
@Component({
  selector: "privacy",
  templateUrl: "./privacy.component.html",
  styleUrls: ["./privacy.component.scss"]
})
export class PrivacyComponent {
  constructor(
    @Inject(DOCUMENT) public document: Document,
    public logoService: LogoService
  ) {}
}
