import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { YelpInviteDialogComponent } from "../dialogs/yelp-invite-dialog/yelp-invite-dialog.component";

@Injectable({
  providedIn: "root"
})
export class SourceDialogService {
  constructor(private dialog: MatDialog) {}

  openInviteDialog = async (locationsNumber?) => {
    this.dialog.open(YelpInviteDialogComponent, {
      maxWidth: "800px",
      width: "100%",
      closeOnNavigation: false,
      data: {
        locationsNumber
      }
    });
  };
}
