import { Component } from "@angular/core";

@Component({
  selector: "response-time-blog",
  templateUrl: "response-time-blog.component.html",
  styleUrls: ["response-time-blog.component.scss"]
})
export class ResponseTimeBlogComponent {
  constructor() {}
}
