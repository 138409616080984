import { Injectable } from "@angular/core";
import { ConnectionService } from "./connection.service";
import { WorkizIntegration } from "../../../../server/src/db/classes/notification-integration.type";
import { OperationConfigType } from "../../../../server/src/db/classes/operation-config.type";

@Injectable({
  providedIn: "root"
})
export class WorkizIntegrationService {
  constructor(private connectionService: ConnectionService) {}

  async manageAccessToken(
    id: string,
    type: OperationConfigType,
    accessToken: string,
    apiSecret: string
  ): Promise<WorkizIntegration> {
    return await this.connectionService.post("/operation-config/integration/workiz/manageAccessToken", {
      id,
      type,
      accessToken,
      apiSecret
    });
  }
}
