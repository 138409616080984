import { Component } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { DataService } from "../../Services/data.service";
import { SessionService } from "../../Services/session.service";
import { filter } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";
import { Source } from "../../../../../server/src/db/classes/source";

@UntilDestroy()
@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"]
})
export class MainComponent {
  public ready: boolean;

  constructor(
    public dataService: DataService,
    private router: Router,
    private sessionService: SessionService
  ) {
    this.init();

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(untilDestroyed(this))
      .subscribe((item: NavigationEnd) => {
        if (this.ready) {
          this.dataService.openAddTokenDialogIfNoSources();
        }
      });

    if ((window as any).FS)
      (window as any).FS("setIdentity", {
        uid: sessionService.session.id,
        properties: {
          displayName: sessionService.session.name,
          email: sessionService.session.email
          // Add your own custom user variables here, details at
        }
      });
  }

  private init = async () => {
    await this.dataService.updateBusinessAndSources();
    if (
      this.router.url !== "app/sources" &&
      !this.dataService.allSources$.value.some((s: Source) => s.enabled && !s.billingPaused && !s.billingWarn)
    ) {
      this.router.navigateByUrl("/app/sources");
    }
    this.ready = true;
  };
}
