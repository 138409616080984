import { Component } from "@angular/core";

@Component({
  selector: "blog",
  templateUrl: "blog.component.html",
  styleUrls: ["blog.component.scss"]
})
export class BlogComponent {
  constructor() {}
}
