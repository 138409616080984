<!--<h1 mat-dialog-title>{{ data.source.name || data.source.yelpLocationInfo.name }}</h1>-->
<div mat-dialog-content>
  <div class="form-container">
    <mat-slide-toggle [(ngModel)]="data.source.enabled" color="primary" class="activeToggle"
      ><b style="font-size: 18px" [class.greenColor]="data.source.enabled" [class.redColor]="!data.source.enabled">
        <span *ngIf="data.source.enabled; else notActive">Active</span>
        <ng-template #notActive>Not Active - <u>Click here to activate</u></ng-template>
      </b></mat-slide-toggle
    >
    <div [class.opacity-low]="!data.source.enabled">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="data.source.name" />
      </mat-form-field>

      <mat-form-field *ngIf="sessionService.session.role === 'superAdmin'">
        <mat-label>Location ID</mat-label>
        <input matInput [(ngModel)]="data.source.locationId" />
      </mat-form-field>

      <ng-container *ngIf="data.source.ownRole === 'admin'">
        <h3>Parent Business</h3>
        <mat-form-field>
          <mat-label>Business name</mat-label>
          <input
            type="text"
            matInput
            [formControl]="businessSearchControl"
            [matAutocomplete]="auto"
            [disabled]="data.source.ownRole !== 'admin'" />
          <mat-icon matPrefix>location_city</mat-icon>
          <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="businessSelected($event)">
            <mat-option *ngFor="let business of filteredBusinesses | async" [value]="business">
              <div class="flexRowParent">
                <mat-icon>location_city</mat-icon>
                {{ business.name }}
              </div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>

      <!--      <button class="flexItem" mat-button mat-raised-button>Move</button>-->

      <mat-slide-toggle
        matTooltip="To transfer this source to a different Source-Token (Yelp Profile):&#13;
1. Toggle the setting and save.&#13;
2. Add/refresh the new token that contains this source.&#13;
The source will then be under the new Source-Token."
        matTooltipClass="custom-tooltip"
        [(ngModel)]="data.source.allowYelpTokenTransfer"
        *ngIf="data.source.ownRole === 'admin'"
        color="primary">
        Allow transfer to another Source-Token
      </mat-slide-toggle>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="this.dialogRef.close()">Cancel</button>
  <button
    mat-button
    mat-raised-button
    color="primary"
    [disabled]="savingInProgress"
    (click)="save(data.source)"
    cdkFocusInitial>
    Save
  </button>
</div>
