import { Component, Input, Optional, OnInit } from "@angular/core";
import { OperationConfigBaseComponent } from "../operation-config-base.component";
import { OperationConfigComponent } from "../operation-config.component";
import { YelpDayOfWeek, TimePeriod, WeekDayConfig } from "../../../../../../server/src/db/classes-helpers/yelpConfig";
import { FormControl, Validators } from "@angular/forms";
import { NgxMaterialTimepickerComponent } from "ngx-material-timepicker";

interface DayTimeRanges {
  day: YelpDayOfWeek | "AnyDay";
  enabled: boolean;
  timeRanges: TimePeriod[];
  isAlwaysOn?: boolean;
}

type ScheduleType = "anyDay" | "specificDays" | "alwaysOn";

const DEFAULT_TIME_PERIOD = { start: "08:00", end: "20:00" };

@Component({
  selector: "app-operation-config-timing",
  templateUrl: "./operation-config-timing.component.html",
  styleUrl: "./operation-config-timing.component.scss"
})
export class OperationConfigTimingComponent extends OperationConfigBaseComponent<WeekDayConfig> implements OnInit {
  @Input() override inputConfig: WeekDayConfig | null;
  @Input() override inputGlobalConfig: WeekDayConfig;

  days = Object.values(YelpDayOfWeek);
  timeControls: Map<string, FormControl> = new Map();
  initialScheduleType: ScheduleType = "anyDay";
  scheduleType: ScheduleType = "anyDay";
  anyDayTimeRanges: TimePeriod[] = [];
  daySchedules: DayTimeRanges[] = [];
  initialResponseGracePeriod: number = 30;

  constructor(public override operationConfigComponent: OperationConfigComponent) {
    super(operationConfigComponent);
  }

  override ngOnInit() {
    super.ngOnInit();

    if (!this.config) {
      this.config = this.createDefaultConfig();
    }

    if (this.config.initialResponseGracePeriod) {
      this.initialResponseGracePeriod = this.config.initialResponseGracePeriod;
    }

    this.initializeUIModel();
    this.initializeTimeControls();
    this.updateControlsDisabledState();
  }

  initializeUIModel(): void {
    const isAlwaysOn =
      this.config.AnyDay &&
      this.config.AnyDay.length === 1 &&
      this.config.AnyDay[0].start === "00:00" &&
      this.config.AnyDay[0].end === "24:00";

    if (isAlwaysOn) {
      this.scheduleType = "alwaysOn";
    } else if (this.config.AnyDay && this.config.AnyDay.length > 0) {
      this.scheduleType = "anyDay";
    } else {
      this.scheduleType = "specificDays";
    }

    this.initialScheduleType = this.scheduleType;

    this.anyDayTimeRanges = this.config.AnyDay || [{ ...DEFAULT_TIME_PERIOD }];

    this.daySchedules = this.days.map(day => {
      const dayConfig = this.config[day];
      const isAlwaysOn =
        dayConfig && dayConfig.length === 1 && dayConfig[0].start === "00:00" && dayConfig[0].end === "24:00";

      return {
        day: day,
        enabled: !!dayConfig && dayConfig.length > 0,
        timeRanges: dayConfig || [],
        isAlwaysOn: isAlwaysOn
      };
    });
  }

  initializeTimeControls(): void {
    this.timeControls.clear();

    if (this.anyDayTimeRanges) {
      this.anyDayTimeRanges.forEach((timeRange, index) => {
        this.createTimeRangeControls(`any_${index}`, timeRange);
      });
    }

    this.daySchedules.forEach((daySchedule, dayIndex) => {
      if (daySchedule.timeRanges) {
        daySchedule.timeRanges.forEach((timeRange, rangeIndex) => {
          this.createTimeRangeControls(`day_${dayIndex}_${rangeIndex}`, timeRange);
        });
      }
    });
  }

  createTimeRangeControls(id: string, timeRange: TimePeriod): void {
    const startId = `${id}_start`;
    const endId = `${id}_end`;

    const startControl = new FormControl(
      {
        value: this.getTimeDisplay(timeRange.start),
        disabled: this.isGlobalConfigApplied
      },
      [Validators.required]
    );

    this.timeControls.set(startId, startControl);

    const endControl = new FormControl(
      {
        value: this.getTimeDisplay(timeRange.end),
        disabled: this.isGlobalConfigApplied
      },
      [Validators.required]
    );

    this.timeControls.set(endId, endControl);
  }

  onGracePeriodChange(): void {
    if (this.initialResponseGracePeriod < 0) {
      this.initialResponseGracePeriod = 0;
    } else if (this.initialResponseGracePeriod > 1440) {
      this.initialResponseGracePeriod = 1440;
    }

    this.notifyConfigChange();
  }

  getTimeControl(id: string, field: "start" | "end"): FormControl {
    const controlId = `${id}_${field}`;

    if (!this.timeControls.has(controlId)) {
      const control = new FormControl(
        {
          value: "",
          disabled: this.isGlobalConfigApplied
        },
        [Validators.required]
      );

      this.timeControls.set(controlId, control);
    }

    return this.timeControls.get(controlId) as FormControl;
  }

  updateControlsDisabledState(): void {
    this.timeControls.forEach(control => {
      if (this.isGlobalConfigApplied) {
        control.disable();
      } else {
        control.enable();
      }
    });
  }

  override toggleGlobalSettings(): void {
    super.toggleGlobalSettings();
    this.updateControlsDisabledState();
  }

  protected createDefaultConfig(): WeekDayConfig {
    return {
      AnyDay: [{ ...DEFAULT_TIME_PERIOD }]
    };
  }

  updateConfigFromUIModel(): void {
    const newConfig: WeekDayConfig = {
      initialResponseGracePeriod: this.initialResponseGracePeriod
    };

    if (this.scheduleType === "alwaysOn") {
      newConfig.AnyDay = [{ start: "00:00", end: "24:00" }];
    } else if (this.scheduleType === "anyDay") {
      if (this.anyDayTimeRanges.length > 0) {
        newConfig.AnyDay = [...this.anyDayTimeRanges];
      }
    } else if (this.scheduleType === "specificDays") {
      this.daySchedules.forEach(daySchedule => {
        if (daySchedule.enabled && daySchedule.timeRanges.length > 0) {
          newConfig[daySchedule.day] = [...daySchedule.timeRanges];
        }
      });
    }

    this.config = newConfig;
  }

  protected override notifyConfigChange(): void {
    this.updateConfigFromUIModel();

    if (this.operationConfigComponent && this.config) {
      this.operationConfigComponent.followUpHoursConfigChanged(this.config, this.isGlobalConfigApplied);
    }
  }

  addTimeRange(daySchedule: DayTimeRanges): void {
    daySchedule.timeRanges.push({ ...DEFAULT_TIME_PERIOD });

    const dayIndex = this.daySchedules.indexOf(daySchedule);
    this.createTimeRangeControls(
      `day_${dayIndex}_${daySchedule.timeRanges.length - 1}`,
      daySchedule.timeRanges[daySchedule.timeRanges.length - 1]
    );

    this.notifyConfigChange();
  }

  addAnyDayTimeRange(): void {
    this.anyDayTimeRanges.push({ ...DEFAULT_TIME_PERIOD });

    this.createTimeRangeControls(
      `any_${this.anyDayTimeRanges.length - 1}`,
      this.anyDayTimeRanges[this.anyDayTimeRanges.length - 1]
    );

    this.notifyConfigChange();
  }

  removeTimeRange(daySchedule: DayTimeRanges, index: number): void {
    daySchedule.timeRanges.splice(index, 1);
    this.initializeTimeControls();
    this.notifyConfigChange();
  }

  removeAnyDayTimeRange(index: number): void {
    if (this.anyDayTimeRanges && this.anyDayTimeRanges.length > 1) {
      this.anyDayTimeRanges.splice(index, 1);
      this.initializeTimeControls();
      this.notifyConfigChange();
    }
  }

  onScheduleTypeChange(): void {
    if (this.scheduleType === "alwaysOn") {
      // For alwaysOn, we clear all specific configurations
      this.anyDayTimeRanges = [];
      this.daySchedules.forEach(daySchedule => {
        daySchedule.enabled = false;
        daySchedule.timeRanges = [];
      });
    } else if (this.initialScheduleType !== "specificDays" && this.scheduleType === "specificDays") {
      this.daySchedules = [];
      this.timeControls.clear();

      this.days.forEach(day => {
        this.daySchedules.push({
          day,
          enabled: true,
          timeRanges: [{ ...DEFAULT_TIME_PERIOD }]
        });
      });

      this.daySchedules.forEach((daySchedule, dayIndex) => {
        this.createTimeRangeControls(`day_${dayIndex}_0`, daySchedule.timeRanges[0]);
      });
    } else if (this.scheduleType === "anyDay") {
      this.anyDayTimeRanges = [{ ...DEFAULT_TIME_PERIOD }];
      this.initializeTimeControls();
    }

    this.notifyConfigChange();
  }

  onDayEnabledChange(daySchedule: DayTimeRanges): void {
    if (daySchedule.enabled && daySchedule.timeRanges.length === 0) {
      daySchedule.timeRanges.push({ ...DEFAULT_TIME_PERIOD });

      const dayIndex = this.daySchedules.indexOf(daySchedule);
      this.createTimeRangeControls(`day_${dayIndex}_0`, daySchedule.timeRanges[0]);
    }

    this.notifyConfigChange();
  }

  openTimePicker(picker: NgxMaterialTimepickerComponent): void {
    if (!this.isGlobalConfigApplied) {
      picker.open();
    }
  }

  onDayAlwaysOnChange(daySchedule: DayTimeRanges): void {
    if (daySchedule.isAlwaysOn) {
      daySchedule.timeRanges = [{ start: "00:00", end: "24:00" }];
    } else if (
      daySchedule.timeRanges.length === 1 &&
      daySchedule.timeRanges[0].start === "00:00" &&
      daySchedule.timeRanges[0].end === "24:00"
    ) {
      daySchedule.timeRanges = [{ ...DEFAULT_TIME_PERIOD }];
    }

    this.initializeTimeControls();
    this.notifyConfigChange();
  }

  onTimeChange(time: string, timeRange: TimePeriod, field: "start" | "end", id: string): void {
    if (!time) return;

    const control = this.getTimeControl(id, field);
    control.setValue(time);

    let hours24: number;
    let minutes: number;

    if (time.includes(" ")) {
      // Format "9:30 AM" or "12:00 PM"
      const [timeValue, period] = time.split(" ");
      const [hours, mins] = timeValue.split(":").map(part => parseInt(part, 10));

      minutes = mins;
      hours24 = hours;

      if (period.toLowerCase() === "pm" && hours !== 12) {
        hours24 = hours + 12;
      } else if (period.toLowerCase() === "am" && hours === 12) {
        hours24 = 0;
      }
    } else if (time.includes(":")) {
      const [hours, mins] = time.split(":").map(part => parseInt(part, 10));
      hours24 = hours;
      minutes = mins;
    } else {
      console.error("Unknown format of time:", time);
      return;
    }

    if (isNaN(hours24) || isNaN(minutes) || hours24 < 0 || hours24 > 23 || minutes < 0 || minutes > 59) {
      console.error("Invalid time:", time);
      return;
    }

    timeRange[field] = `${hours24.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

    this.notifyConfigChange();
  }

  getTimeDisplay(time24: string): string {
    if (!time24 || !time24.includes(":")) return "";

    const [hours, minutes] = time24.split(":").map(part => parseInt(part, 10));

    let hours12 = hours % 12;
    if (hours12 === 0) hours12 = 12;

    const period = hours >= 12 ? "PM" : "AM";

    return `${hours12}:${minutes.toString().padStart(2, "0")} ${period}`;
  }
}
