import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LogoService } from "../../Services/logo-service";
import { RevokeRefreshTokenService } from "src/app/Services/revoke-refresh-token.service";
import { NzDialogService } from "src/app/utils/services/nz-dialog.service";
import { ConfirmDialogComponent } from "src/app/dialogs/confirm-dialog/confirm-dialog.component";

@UntilDestroy()
@Component({
  selector: "app-confirm-revoke-token",
  templateUrl: "./confirm-revoke-token.component.html",
  styleUrls: ["./confirm-revoke-token.component.scss"]
})
export class ConfirmRevokeTokenComponent implements OnInit {
  public sourceTokenId: string | null = null;
  public isSuccess = true;
  public isLoading = false;

  constructor(
    private route: ActivatedRoute,
    private revokeRefreshTokenService: RevokeRefreshTokenService,
    private nzDialogService: NzDialogService,
    public logoService: LogoService
  ) {}

  ngOnInit(): void {
    this.sourceTokenId = this.route.snapshot.queryParamMap.get("source_token_id");

    if (!this.sourceTokenId) {
      this.isSuccess = false;
    }
  }

  public openConfirmDialog(): void {
    const dialogRef = this.nzDialogService.open(ConfirmDialogComponent, {
      maxWidth: 600,
      data: {
        title: "Confirm revoke token",
        mainText: "Please ensure that you are connected to the correct Yelp profile in your browser before proceeding.",
        action: "Confirm"
      }
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(async confirm => {
        if (confirm) {
          this.isLoading = true;
          this.revokeRefreshToken();
        }
      });
  }

  public async revokeRefreshToken(): Promise<void> {
    const response = await this.revokeRefreshTokenService.revoke(this.sourceTokenId);

    if (!response.url) {
      this.isSuccess = false;
      this.isLoading = false;
    }

    window.location.href = response.url;
  }
}
