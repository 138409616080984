<div class="operation-config-container">
  <div class="global-settings-toggle">
    <mat-slide-toggle *ngIf="!isSystemSettings" [checked]="inputConfig" (click)="toggleGlobalSettings()">
      {{ operationConfigComponent.globalConfigText }}
    </mat-slide-toggle>
  </div>

  <div class="grace-period-section">
    <div class="grace-period-input">
      <mat-form-field>
        <mat-label>Grace Period (minutes)</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="initialResponseGracePeriod"
          (change)="onGracePeriodChange()"
          [disabled]="isGlobalConfigApplied"
          min="0"
          max="1440" />
      </mat-form-field>
      <span>
        *Follow-ups will be sent within the Grace Period after a customer's initial message, even outside the 'Follow-up
        Operating Schedule', to ensure timely responses to new conversations. (0 to disable)</span
      >
    </div>
  </div>

  <h4 style="padding-top: 40px">Follow-up Operating Schedule</h4>
  <mat-radio-group [(ngModel)]="scheduleType" (change)="onScheduleTypeChange()">
    <div class="schedule-option">
      <mat-radio-button value="anyDay" [disabled]="isGlobalConfigApplied">Any day</mat-radio-button>
      <mat-radio-button value="specificDays" [disabled]="isGlobalConfigApplied"
        >Specific days and times</mat-radio-button
      >
      <mat-radio-button value="alwaysOn" [disabled]="isGlobalConfigApplied"> Always On (24/7) </mat-radio-button>

      <div *ngIf="scheduleType === 'anyDay'" class="time-ranges">
        <div *ngFor="let timeRange of anyDayTimeRanges; let i = index" class="time-range">
          <div class="time-input-container">
            <mat-form-field>
              <mat-label>From</mat-label>
              <input
                matInput
                [ngxTimepicker]="startTimePicker"
                [format]="12"
                [formControl]="getTimeControl('any_' + i, 'start')"
                readonly />
              <mat-icon matSuffix (click)="openTimePicker(startTimePicker)" class="clickable">access_time</mat-icon>
              <ngx-material-timepicker
                #startTimePicker
                (timeSet)="onTimeChange($event, timeRange, 'start', 'any_' + i)"
                [format]="12"></ngx-material-timepicker>
            </mat-form-field>
          </div>

          <div class="time-input-container">
            <mat-form-field>
              <mat-label>To</mat-label>
              <input
                matInput
                [ngxTimepicker]="endTimePicker"
                [format]="12"
                [formControl]="getTimeControl('any_' + i, 'end')"
                readonly />
              <mat-icon matSuffix (click)="openTimePicker(endTimePicker)" class="clickable">access_time</mat-icon>
              <ngx-material-timepicker
                [disableAnimation]="isGlobalConfigApplied"
                (timeSet)="onTimeChange($event, timeRange, 'end', 'any_' + i)"
                #endTimePicker
                [format]="12"></ngx-material-timepicker>
            </mat-form-field>
          </div>

          <button
            *ngIf="anyDayTimeRanges.length > 1"
            mat-icon-button
            color="warn"
            [disabled]="isGlobalConfigApplied"
            (click)="removeAnyDayTimeRange(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>

        <button mat-button color="primary" [disabled]="isGlobalConfigApplied" (click)="addAnyDayTimeRange()">
          <mat-icon>add</mat-icon> Add Time Range
        </button>
      </div>
    </div>

    <div class="schedule-option">
      <div *ngIf="scheduleType === 'specificDays'" class="days-container">
        <div *ngFor="let daySchedule of daySchedules; let dayIndex = index" class="day-schedule">
          <div class="day-header">
            <mat-checkbox
              [(ngModel)]="daySchedule.enabled"
              [disabled]="isGlobalConfigApplied"
              (change)="onDayEnabledChange(daySchedule)">
              {{ daySchedule.day }}
            </mat-checkbox>

            <mat-checkbox
              *ngIf="daySchedule.enabled"
              [(ngModel)]="daySchedule.isAlwaysOn"
              [disabled]="isGlobalConfigApplied"
              (change)="onDayAlwaysOnChange(daySchedule)"
              class="always-on-checkbox">
              Always On (24/7)
            </mat-checkbox>
          </div>

          <div *ngIf="daySchedule.enabled && !daySchedule.isAlwaysOn" class="time-ranges">
            <div *ngFor="let timeRange of daySchedule.timeRanges; let i = index" class="time-range">
              <div class="time-input-container">
                <mat-form-field>
                  <mat-label>From</mat-label>
                  <input
                    matInput
                    [ngxTimepicker]="dayStartTimePicker"
                    [format]="12"
                    [formControl]="getTimeControl('day_' + dayIndex + '_' + i, 'start')"
                    readonly />
                  <mat-icon matSuffix (click)="openTimePicker(dayStartTimePicker)" class="clickable"
                    >access_time</mat-icon
                  >
                  <ngx-material-timepicker
                    #dayStartTimePicker
                    (timeSet)="onTimeChange($event, timeRange, 'start', 'day_' + dayIndex + '_' + i)"
                    [format]="12"></ngx-material-timepicker>
                </mat-form-field>
              </div>

              <div class="time-input-container">
                <mat-form-field>
                  <mat-label>To</mat-label>
                  <input
                    matInput
                    [ngxTimepicker]="dayEndTimePicker"
                    [format]="12"
                    [formControl]="getTimeControl('day_' + dayIndex + '_' + i, 'end')"
                    readonly />
                  <mat-icon matSuffix (click)="openTimePicker(dayEndTimePicker)" class="clickable"
                    >access_time</mat-icon
                  >
                  <ngx-material-timepicker
                    #dayEndTimePicker
                    (timeSet)="onTimeChange($event, timeRange, 'end', 'day_' + dayIndex + '_' + i)"
                    [format]="12"></ngx-material-timepicker>
                </mat-form-field>
              </div>

              <button
                *ngIf="daySchedule.timeRanges.length > 1"
                mat-icon-button
                color="warn"
                [disabled]="isGlobalConfigApplied"
                (click)="removeTimeRange(daySchedule, i)"
                class="delete-button">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

            <button mat-button color="primary" [disabled]="isGlobalConfigApplied" (click)="addTimeRange(daySchedule)">
              <mat-icon>add</mat-icon> Add Time Range
            </button>
          </div>
          <div *ngIf="!daySchedule.enabled" class="always-on-info">
            <p class="text-muted">Follow-ups will not be sent on {{ daySchedule.day }}</p>
          </div>
          <div *ngIf="daySchedule.enabled && daySchedule.isAlwaysOn" class="always-on-info">
            <p class="text-muted">Follow-ups will be allowed to send during all hours of {{ daySchedule.day }}</p>
          </div>
        </div>
      </div>
    </div>
  </mat-radio-group>
</div>
