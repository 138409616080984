import { Component, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UntilDestroy } from "@ngneat/until-destroy";
import { YelpConfigService } from "src/app/Services/yelp-config.service";
import { OperationConfigType } from "../../../../../server/src/db/classes/operation-config.type";
import { YelpConfig } from "../../../../../server/src/db/classes-helpers/yelpConfig";
import { OperationConfigComponent } from "src/app/components/operation-config/operation-config.component";

interface BaseSourceConfigDialogData {
  type: OperationConfigType;
  name: string;
}

export interface BusinessConfigDialogData extends BaseSourceConfigDialogData {
  type: OperationConfigType.Business;
  businessId: string;
}

export interface LocationConfigDialogData extends BaseSourceConfigDialogData {
  type: OperationConfigType.Location;
  sourceId: string;
}

export interface SystemConfigDialogData extends BaseSourceConfigDialogData {
  type: OperationConfigType.System;
}

export type SourceConfigDialogData = BusinessConfigDialogData | LocationConfigDialogData | SystemConfigDialogData;

@UntilDestroy()
@Component({
  selector: "source-config-dialog",
  templateUrl: "./source-config-dialog.component.html",
  styleUrls: ["./source-config-dialog.component.scss"]
})
export class SourceConfigDialogComponent {
  @ViewChild("appConfig", { static: true }) appConfig: OperationConfigComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SourceConfigDialogData,
    public dialogRef: MatDialogRef<SourceConfigDialogComponent>,
    private yelpConfigService: YelpConfigService
  ) {}

  public async saveSettings(): Promise<void> {
    const yelpConfig: YelpConfig = this.appConfig.locationConfig;

    try {
      switch (this.data.type) {
        case OperationConfigType.Location:
          await this.yelpConfigService.updateBySource(yelpConfig, this.data.sourceId);
          break;
        case OperationConfigType.Business:
          await this.yelpConfigService.updateByBusiness(yelpConfig, this.data.businessId);
          break;
        case OperationConfigType.System:
          await this.yelpConfigService.updateSystemConfig(yelpConfig);
          break;
        default:
          throw new Error("Unsupported operation type");
      }

      this.dialogRef.close();
    } catch (error) {
      console.error("Error updating Yelp config:", error);
    }
  }
}
