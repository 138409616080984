import { Component } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Sort } from "@angular/material/sort";
import { DateTime } from "luxon";
import { debounceTime } from "rxjs";
import { ChartConfiguration, Point } from "chart.js";
import { StorageService } from "../../Services/storage.service";
import { YelpReportService } from "../../Services/yelp-report.service";
import { SourceService } from "../../Services/source.service";
import { DataService } from "../../Services/data.service";
import { BotMessage } from "../../../../../server/src/db/classes/lead";
import { BusinessService } from "../../Services/business.service";

@Component({
  selector: "app-graph-reports",
  templateUrl: "./graph-reports.component.html",
  styleUrls: ["./graph-reports.component.scss"]
})
export class ReportsGraphComponent {
  locationReportsRawData = [];
  originalLocationReports = [];
  sortedLocationReports = [];
  expandedElement: null;
  selectedBusiness: {
    _id: string;
  } = StorageService.getItem("reports.filterBusiness") || {
    _id: BusinessService.ALL_BUSINESSES_NODE_ID
  };
  locations = [];

  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartConfiguration<"scatter">["data"];
  weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  public barChartOptions: ChartConfiguration<"scatter">["options"] = {
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      },
      tooltip: {
        callbacks: {
          label: context => {
            return (
              this.weekdays[Math.round(context.raw["x"])] +
              ", " +
              this.twoNumbers(parseInt(context.raw["y"])) +
              ":" +
              (parseFloat(context.raw["y"]) % 1).toPrecision(2).toString().substr(2, 2)
            );
          }
        }
      }
    },
    layout: {
      padding: 20
    },
    scales: {
      y: {
        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
        position: "left",
        min: 0,
        max: 24,
        ticks: {
          // Include a dollar sign in the ticks
          callback: (value, index, ticks) => {
            return this.twoNumbers(value) + ":00";
          }
        }
      },
      x: {
        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
        min: -0.5,
        max: 6.5,
        ticks: {
          // Include a dollar sign in the ticks
          callback: (value, index, ticks) => {
            return this.weekdays[value];
          }
        }
      }
    }
  };

  private foundData: any | Array<Point | number> = [];
  private notFoundData: any | Array<Point | number> = [];

  updateChartData() {
    this.barChartData = {
      datasets: [
        {
          label: "Phone",
          backgroundColor: "#4ec177",
          pointRadius: 5,
          data: this.foundData //[{x: 4, y: 12}]
        },
        {
          label: "No Phone",
          backgroundColor: "#f44336",
          pointRadius: 5,
          data: this.notFoundData //[{x: 3, y: 5}]
        }
      ]
    };
  }

  columns = [
    { field: "name", title: "Source Name" },
    { field: "totalLeads", title: "Leads" },
    { field: "customerAnswered", title: "Customer Answered" },
    { field: "found", title: "Phones" },
    { field: "followUp", title: "Follow-ups sent" }
    // {field: "handled", title: "Total Incoming/Outgoing Messages"}
  ];
  columnsFields = this.columns.map(x => x.field);
  columnsToDisplayWithExpand = [...this.columns, "expand"];

  range = new FormGroup({
    start: new FormControl(DateTime.now().startOf("month").toJSDate()),
    end: new FormControl(DateTime.now().endOf("month").toJSDate())
  });

  constructor(
    private yelpReportService: YelpReportService,
    private dataService: DataService,
    private sourceService: SourceService
  ) {
    this.prepare();
  }

  private prepare = async () => {
    this.locations = await this.dataService.allSources$.value;
    this.refresh();
  };

  ngAfterViewInit() {
    this.range.valueChanges.pipe(debounceTime(200)).subscribe(event => {
      if (event.start && event.end) {
        this.refresh();
      }
    });
  }

  locationSelectionChanged = ($event: any) => {
    this.selectedBusiness = $event;
    StorageService.setItem("reports.filterBusiness", $event);
    this.refresh();
  };

  private refresh = async () => {
    this.foundData = [];
    this.notFoundData = [];
    this.locationReportsRawData = await this.yelpReportService.get(this.range.value, this.selectedBusiness._id);
    this.originalLocationReports = this.locationReportsRawData.map(location => {
      let sent = 0;
      let greeting = 0;
      let followUp = 0;
      let handled = 0;
      let found = 0;
      let customerAnswered = 0;
      let leadTimes = [];
      location.leads.forEach(lead => {
        const created = DateTime.fromISO(lead.messages[0].time_created).setZone("America/Los_Angeles");
        const newChartPoint = {
          x: created.weekday - 1,
          y: created.hour + created.minute / 100
        };
        if (lead.phoneNumber || lead.maskedNumberExist) {
          newChartPoint.x -= 0.05;
          this.foundData.push(newChartPoint);
        } else {
          newChartPoint.x += 0.05;
          this.notFoundData.push(newChartPoint);
        }

        const firstBizMessage = lead.messages.findIndex(m => m.user_type === "BIZ");
        const lastCustomerMessage = lead.messages.findLastIndex(m => m.user_type === "CONSUMER");
        if (lastCustomerMessage > firstBizMessage) {
          customerAnswered++;
        }

        if (lead.phoneNumber || lead.maskedNumberExist) {
          found++;
        }
        if (lead.messages.length >= 2) {
          const answeredMessage = lead.messages.find((m: BotMessage, index) => {
            return m.user_type === "BIZ" && index !== 0;
          });
          if (answeredMessage) {
            leadTimes.push(
              DateTime.fromISO(answeredMessage.time_created)
                .diff(DateTime.fromISO(lead.messages[0].time_created))
                .as("seconds")
            );
          }
        }
        let firstBotMessage = true;
        lead.messages.forEach((message, idx) => {
          if (message.user_type === "BIZ") {
            if (firstBotMessage) {
              greeting++;
              firstBotMessage = false;
            } else {
              followUp++;
            }
            sent++;
          }
          handled++;
        });
      });
      leadTimes = leadTimes.filter(x => x < 5000);
      let leadTimesAverage = 0;
      if (leadTimes.length > 0) {
        leadTimesAverage = leadTimes?.reduce((a, b) => a + b) / leadTimes.length;
      }
      leadTimesAverage = parseInt(leadTimesAverage.toString());
      return {
        name: location.name,
        greeting,
        followUp,
        sent,
        customerAnswered: `${customerAnswered} (${parseInt(String((customerAnswered / location.leads.length) * 100)) || 0}%)`,
        found: `${found} (${parseInt(String((found / location.leads.length) * 100)) || 0}%)`,
        handled,
        leadTimesAverage,
        totalLeads: location.leads.length
      };
    });

    this.announceSortChange({ active: "found", direction: "desc" });
    this.updateChartData();
  };

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  announceSortChange = (sort: Sort) => {
    const data = this.originalLocationReports.slice();
    this.sortedLocationReports = data.sort((a, b) => {
      return this.compare(a[sort.active], b[sort.active], sort.direction === "asc");
    });
  };

  private twoNumbers(value: number | string) {
    return value.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false
    });
  }
}
