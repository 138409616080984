import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { SessionService } from "./session.service";
import { catchError, lastValueFrom, Subject, throwError } from "rxjs";
import { ConfirmDialogComponent } from "../dialogs/confirm-dialog/confirm-dialog.component";
import { NzDialogService } from "../utils/services/nz-dialog.service";
import { SourceDialogService } from "./source-dialog.service";

@Injectable({
  providedIn: "root"
})
export class ConnectionService {
  private authError = new Subject<void>();
  authError$ = this.authError.asObservable();

  constructor(
    private http: HttpClient,
    private nzDialogService: NzDialogService,
    private sessionService: SessionService,
    private sourceDialogService: SourceDialogService
  ) {}

  async post(request, body = {}, method = "post", preventErrorPopup = false): Promise<any> {
    if (!request.startsWith("/")) {
      request = "/" + request;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token"
      })
    };
    const sessionId = this.sessionService.sessionId;
    if (sessionId) {
      httpOptions.headers = httpOptions.headers.set("Authorization", sessionId);
    }

    // return lastValueFrom(this.http.post('//api.' + document.location.hostname + request, body, httpOptions)
    // return lastValueFrom(this.http.post('/api' +  request, body, httpOptions)//DEV NEED TO WORK ON EVIRONMENT VARS
    return lastValueFrom(
      this.http[method](environment.server + "/api" + request, body, httpOptions).pipe(
        catchError(error => (preventErrorPopup ? throwError(() => error) : this.handleError(error)))
      )
    );
  }

  private handleError = (response: HttpErrorResponse) => {
    if (response.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", response.error);
    } else if (response.status === 401) {
      this.handleAuthError(response);
    } else if (response.status === 403 && response.error.message === "No Entities was added to the user") {
      this.sourceDialogService.openInviteDialog(0);
    } else {
      if (response.error && !response.error.errorCode) {
        this.showErrorDialog(response.error);
      }
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${response.status}, body was: `, response.error);
    }
    // Return an observable with a user-facing error message.
    throw response.error;
    // return throwError(() => new Error('Something happened; please try again later.'));
  };

  private handleAuthError = (response: HttpErrorResponse) => {
    if (
      ["session not found", "user not found"].includes(response.error.message) &&
      !response.url.endsWith("api/login/logout")
    ) {
      this.authError.next();
    }
  };

  private showErrorDialog(error: any) {
    if (typeof error === "string" || error instanceof String) {
      error = { e: error };
    }

    this.nzDialogService.open(ConfirmDialogComponent, {
      width: "500px",
      maxHeight: "80%",
      backdropClass: "CLASSA",
      panelClass: "CLASSB",
      closeOnNavigation: false,
      data: {
        title: error.e?.title || "Error",
        mainText:
          error.e?.message || error.e || "An unexpected error occurred. Please contact support if this continues.",
        hideConfirm: true,
        CancelText: "Ok"
      }
    });
  }
}
