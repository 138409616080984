import { Directive, ElementRef, HostBinding, Input, NgZone } from "@angular/core";

@Directive({
  selector: "[nzLabel]"
})
export class NzLabelDirective {
  @HostBinding("class.nzLabelDirective") nzLabelDirective = true;
  @Input() @HostBinding("class.ellipsis") nzLabelEnabled = true;
  @Input() labelTitle;
  @Input() ngClass: any;
  @Input() forceTitle: false;
  @Input() parentSelectorForTitle: string;
  @Input() postfixTitleInNewRow;
  @Input() parentForTitle;
  static debounce;

  constructor(
    private hostElement: ElementRef,
    private zone: NgZone
  ) {}

  ngAfterViewInit() {
    this.parentForTitle =
      this.parentForTitle || this.getParent(this.parentSelectorForTitle, this.hostElement.nativeElement);
    this.zone.runOutsideAngular(() => {
      this.parentForTitle.addEventListener("mousemove", this.mousemove);
    });
  }

  ngOnDestroy() {
    this.parentForTitle?.removeEventListener("mousemove", this.mousemove);
  }

  mousemove = () => {
    if (NzLabelDirective.debounce) {
      return;
    }
    NzLabelDirective.debounce = setTimeout(() => {
      NzLabelDirective.debounce = undefined;
    }, 200);

    const originalDisplay = this.hostElement.nativeElement.style.display;
    const cappedWidth = this.hostElement.nativeElement.getBoundingClientRect().width;
    this.hostElement.nativeElement.style.display = "inline-table";
    this.hostElement.nativeElement.style.overflow = "visible";
    const fullWidth = this.hostElement.nativeElement.getBoundingClientRect().width;
    this.hostElement.nativeElement.style.display = originalDisplay;
    this.hostElement.nativeElement.style.overflow = "hidden";

    let title = "";
    if (cappedWidth < fullWidth || this.forceTitle) {
      title = this.hostElement.nativeElement.textContent;
      if (this.labelTitle) {
        title = this.labelTitle;
      }
    }

    if (this.postfixTitleInNewRow) {
      title += (title ? "\r\n" : "") + this.postfixTitleInNewRow;
    }

    if (title) {
      this.parentForTitle.setAttribute("title", title.trim());
    } else {
      this.parentForTitle.removeAttribute("title");
    }
  };

  private getParent(parentSelector: string, parentElement) {
    return parentSelector ? this.getParents(this.hostElement.nativeElement, parentSelector)[0] : parentElement;
  }

  getParents = (el, parentSelector?) => {
    // If no parentSelector defined will bubble up all the way to *document*
    if (parentSelector === undefined) {
      parentSelector = document;
    }

    const parents = [];
    let p = el.parentNode;

    while (p !== parentSelector) {
      const o = p;
      parents.push(o);
      p = o.parentNode;
    }
    parents.push(parentSelector); // Push that parentSelector you wanted to stop at

    return parents;
  };
}
