import { Component, Inject, Optional } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Business } from "../../../../../server/src/db/classes/business";
import { DataService } from "../../Services/data.service";
import { cloneDeep } from "lodash";
import { BusinessService } from "../../Services/business.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SessionService } from "../../Services/session.service";
import { StringConstants } from "../../../../../server/src/helpers/string-constants";

@UntilDestroy()
@Component({
  selector: "business-dialog",
  templateUrl: "./business-dialog.component.html",
  styleUrls: ["./business-dialog.component.scss"]
})
export class BusinessDialogComponent {
  allBusinessesFlatById: { [key: string]: Business | any } = {};
  allBusinessesFlat = [];
  saving = false;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<BusinessDialogComponent>,
    private businessService: BusinessService,
    private _snackBar: MatSnackBar,
    public sessionService: SessionService,
    private dataService: DataService
  ) {
    this.data.business = cloneDeep(this.data.business);
    if (!data.business.parent) {
      data.business.parent = StringConstants.AllBusinessesId;
    }
    dataService.allBusinessesHashedById$.pipe(untilDestroyed(this)).subscribe(allBusinessesFlatById => {
      this.allBusinessesFlatById = allBusinessesFlatById;
      this.allBusinessesFlat = Object.values(this.allBusinessesFlatById);
    });
  }

  async save() {
    this.saving = true;
    try {
      await this.businessService.update(this.data.business);
    } catch (e) {
      this._snackBar.open(e, undefined, {
        duration: 3000
      });
      this.saving = false;
      return;
    }
    await this.dataService.updateBusinessAndSources();
    this.dialogRef.close();
  }

  /**
   * Checks if a business is in the parent chain of another business
   */
  private isInParentChain(businessId: string, targetId: string): boolean {
    let current = this.allBusinessesFlatById[targetId];
    while (current?.parent) {
      if (current.parent.toString() === businessId) return true;
      current = this.allBusinessesFlatById[current.parent.toString()];
    }
    return false;
  }

  /**
   * Filters out businesses that can't be parents:
   * - The business itself (can't be its own parent)
   * - Any business in its parent chain (would create circular reference)
   * - Any direct children (would create immediate circular reference)
   */
  filterSelf = (businesses: Business[]) => {
    const currentId = this.data.business._id?.toString();
    
    return businesses.filter(b => {
      // Allow selection if this is a new business
      if (!currentId) return true;

      const businessId = b._id?.toString();
      
      // Can't be its own parent
      if (businessId === currentId) return false;
      
      // Can't select direct children as parent
      if (b.parent?.toString() === currentId) return false;

      // Can't select if business is already in the parent chain
      if (this.isInParentChain(currentId, businessId)) return false;
      
      return true;
    });
  };
}
