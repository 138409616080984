import { AfterViewInit, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { NotificationIntegrationFactory } from "../../../../../../server/src/db/classes/notification-integration.factory";
import {
  NotificationIntegration,
  NotificationIntegrationBase,
  NotificationIntegrationDefault,
  NotificationIntegrationDefaultParams,
  NotificationIntegrationType,
  NotificationIntegrations
} from "../../../../../../server/src/db/classes/notification-integration.type";
import { OperationConfigBaseComponent } from "../operation-config-base.component";
import { OperationConfigComponent } from "../operation-config.component";
import { SourceConfigDialogData } from "src/app/dialogs/source-config-dialog/source-config-dialog.component";
import { DataService } from "src/app/Services/data.service";
import { OperationConfigType } from "../../../../../../server/src/db/classes/operation-config.type";
import { SlackIntegrationService } from "src/app/Services/slack-integration.service";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { DisplayErrorDialogComponent } from "src/app/dialogs/display-error-dialog/display-error-dialog.component";

@Component({
  selector: "app-integrations",
  templateUrl: "./integrations.component.html",
  styleUrl: "./integrations.component.scss"
})
export class IntegrationsComponent
  extends OperationConfigBaseComponent<NotificationIntegrations>
  implements AfterViewInit
{
  @Input() override inputConfig: NotificationIntegrations | null;
  @Input() override inputGlobalConfig: NotificationIntegrations;
  @Input() sourceConfig: SourceConfigDialogData;

  @ViewChild("settingsContainer") private settingsContainer: ElementRef;

  public selectedIntegration: NotificationIntegrationType | null = null;
  public notificationIntegrationType = NotificationIntegrationType;
  public isConnectButtonLoading = false;

  constructor(
    public override operationConfigComponent: OperationConfigComponent,
    private dataService: DataService,
    private matDialog: MatDialog,
    private slackIntegrationService: SlackIntegrationService
  ) {
    super(operationConfigComponent);
  }

  ngAfterViewInit(): void {
    this.initialize();
  }

  private initialize(): void {
    if (this.config) {
      this.config = this.mergedConfig(this.config as Array<NotificationIntegration>);
      this.globalConfig = this.mergedConfig(this.globalConfig as Array<NotificationIntegration>);
      this.sortIntegrations();
      this.sortIntegrations(this.globalConfig);
    }
  }

  private sortIntegrations(config?): void {
    config = config || this.config;
    if (config) {
      config.sort((a, b) => {
        // Sort by enabled status first (enabled integrations come first)
        if (a.enabled !== b.enabled) {
          return a.enabled ? -1 : 1;
        }
        // If enabled status is the same, sort by name
        return a.type.localeCompare(b.type);
      });
    }
  }

  private get baseIntegrations(): NotificationIntegrations {
    const factory = new NotificationIntegrationFactory();
    return factory.baseIntegrations;
  }

  protected override notifyConfigChange(): void {
    this.sortIntegrations(); // Apply sorting after config changes
    this.operationConfigComponent.notificationIntegrationsNotifyConfigChaged(this.config, this.isGlobalConfigApplied);
  }

  public getDefaults(integration: NotificationIntegration): NotificationIntegrationDefaultParams {
    return new NotificationIntegrationDefault(integration);
  }

  public selectIntegration(type: NotificationIntegrationType | null) {
    this.selectedIntegration = this.selectedIntegration === type ? null : type;
    this.sortIntegrations();

    // If we're deselecting an integration (type is null), also apply sorting
    // This covers the case when toggling global settings
    if (type === null) {
      this.settingsContainer.nativeElement.scrollTop = 0;
      setTimeout(() => this.sortIntegrations(), 0);
    }
  }

  private mergedConfig(config: NotificationIntegrations): NotificationIntegrations {
    return [...config, ...this.baseIntegrations].reduce((acc, current) => {
      const x = acc.find(item => item.type === current.type);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  }

  public getIntegration<T extends NotificationIntegration>(
    integration: NotificationIntegration,
    type: NotificationIntegrationType
  ): T {
    const defaultValues: NotificationIntegrationBase = { enabled: false, type };
    return integration && integration.type === type ? (integration as T) : (defaultValues as T);
  }

  private get operationConfigId(): string {
    switch (this.sourceConfig.type) {
      case OperationConfigType.Location: {
        return this.dataService.allSourcesHashedById$.value[this.sourceConfig.sourceId].locationConfig$;
      }
      case OperationConfigType.Business: {
        return this.sourceConfig.businessId;
      }
      default:
        throw new Error("Unsupported operation type");
    }
  }

  public async initiateSlackOAuth(): Promise<void> {
    try {
      this.isConnectButtonLoading = true;
      const { authUrl } = await this.slackIntegrationService.getSlackOAuthUrl(
        this.operationConfigId,
        this.sourceConfig.type
      );
      window.location.href = authUrl;
      this.isConnectButtonLoading = false;
    } catch (e) {
      console.error("Error initiating Slack OAuth:", e);
    }
  }

  getButtonLabel(integration: NotificationIntegration): string {
    if (this.selectedIntegration === integration.type) {
      return "Close";
    }
    return integration.enabled ? "View Integration" : "Connect";
  }

  getButtonClass(integration: NotificationIntegration): string {
    return integration.enabled ? "connected-button" : "connect-button";
  }

  async handleIntegrationAction(integration: NotificationIntegration): Promise<void> {
    if (integration.type === NotificationIntegrationType.Slack) {
      if (!integration.accessToken) {
        return await this.initiateSlackOAuth();
      }
    }

    this.selectIntegration(integration.type);
  }

  async displayError(integration: NotificationIntegration): Promise<void> {
    this.openDialog({
      data: {
        integration,
        operationConfigId: this.operationConfigId
      }
    });
  }

  private openDialog(config: MatDialogConfig = {}): MatDialogRef<DisplayErrorDialogComponent> {
    const defaultConfig: MatDialogConfig = {
      width: "80%",
      closeOnNavigation: false
    };

    return this.matDialog.open(DisplayErrorDialogComponent, { ...defaultConfig, ...config });
  }
}
