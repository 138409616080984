import { cloneDeep } from "lodash";
import { Component, HostBinding, Inject, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SessionService } from "../../Services/session.service";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { FlatTreeControl } from "@angular/cdk/tree";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { StorageService } from "../../Services/storage.service";
import { NzDialogService } from "../../utils/services/nz-dialog.service";
import { DataService } from "../../Services/data.service";
import { EntityAccountsDialogComponent } from "../entity-permissions-dialog/entity-accounts-dialog.component";
import { Business } from "../../../../../server/src/db/classes/business";
import { OperationConfigType } from "../../../../../server/src/db/classes/operation-config.type";
import { BusinessService } from "../../Services/business.service";
import { BusinessDialogComponent } from "../business-dialog/business-dialog.component";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { BillingService } from "../../Services/billing.service";
import {
  BusinessConfigDialogData,
  SourceConfigDialogComponent,
  SystemConfigDialogData
} from "../source-config-dialog/source-config-dialog.component";

@UntilDestroy()
@Component({
  selector: "business-picker-dialog",
  templateUrl: "./business-picker-dialog.component.html",
  styleUrls: ["./business-picker-dialog.component.scss"]
})
export class BusinessPickerDialogComponent {
  @HostBinding("class.displayNone") closing: boolean = false;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dataService: DataService,
    public billingService: BillingService,
    public sessionService: SessionService,
    public dialogRef: MatDialogRef<BusinessPickerDialogComponent>,
    public businessService: BusinessService,
    private nzDialogService: NzDialogService,
    private dialog: MatDialog
  ) {
    dataService.allBusinessesTree$.pipe(untilDestroyed(this)).subscribe(businesses => {
      this.dataSource.data = businesses;
      this.treeControl.expandAll();
    });
  }

  ngOnDestroy() {
    this.closing = true;
  }

  private _transformer = (node: Business, level: number) => {
    node["expandable"] = !!node.children && node.children.length > 0;
    node["level"] = level;
    return node;
  };

  treeControl = new FlatTreeControl<Business>(
    node => node["level"],
    node => node["expandable"]
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node["level"],
    node => node["expandable"],
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  selectBusiness = async business => {
    await this.dataService.selectBusiness(business);
  };

  addEditBusiness(business, isAdd = false) {
    const biz = isAdd
      ? {
          parent: this.dataService.allBusinessesHashedById$.value[business._id]._id
        }
      : this.dataService.allBusinessesHashedById$.value[business._id];

    this.nzDialogService.open(BusinessDialogComponent, {
      maxWidth: "500px",
      data: {
        business: biz
      }
    });
  }

  editBusinessAccounts = async business => {
    const dialogRef = this.nzDialogService.open(EntityAccountsDialogComponent, {
      width: "400px",
      maxHeight: "80%",
      backdropClass: "CLASSA",
      panelClass: "CLASSB",
      closeOnNavigation: false,
      data: cloneDeep(business)
    });
    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(async business => {
        if (business) {
          await this.businessService.update(business);
          await this.dataService.updateBusinessAndSources();
        }
      });
  };

  isAdmin = (business: Business) => {
    if (!business || !business.accounts) {
      return false;
    }
    if (business.accounts.find(a => a.email === this.sessionService.session.email)?.permissions[0] === "admin") {
      return true;
    } else {
      return this.isAdmin(this.dataService.allBusinessesHashedById$.value[business.parent?.toString()]);
    }
  };

  delete(business) {
    const dialogRef = this.nzDialogService.open(ConfirmDialogComponent, {
      width: "500px",
      maxHeight: "80%",
      backdropClass: "CLASSA",
      panelClass: "CLASSB",
      closeOnNavigation: false,
      data: {
        title: "Confirm Delete!",
        mainText: `Are you sure you want to delete the business:\n"${business.name}"?`,
        comment: `*This operation is irreversible.\n*All the sources will still be available for the token owner.`,
        action: "DELETE!"
      }
    });
    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(async confirm => {
        if (confirm) {
          await this.businessService.delete(business);
          await this.dataService.updateBusinessAndSources();
        }
      });
  }

  public editSystemSettings(): void {
    const data: SystemConfigDialogData = {
      type: OperationConfigType.System,
      name: "System"
    };

    const dialogRef = this.dialog.open(SourceConfigDialogComponent, {
      maxWidth: "800px",
      width: "100%",
      height: "100%",
      backdropClass: "CLASSA",
      panelClass: "CLASSB",
      closeOnNavigation: false,
      data
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(async () => {});
  }

  public editLocationSettings(businessId: string, name: string): void {
    const data: BusinessConfigDialogData = {
      businessId,
      name,
      type: OperationConfigType.Business
    };

    const dialogRef = this.dialog.open(SourceConfigDialogComponent, {
      maxWidth: "800px",
      width: "100%",
      height: "100%",
      backdropClass: "CLASSA",
      panelClass: "CLASSB",
      closeOnNavigation: false,
      data
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(async () => {});
  }
}
