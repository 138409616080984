import { NgModule } from "@angular/core";
import { HomeComponent } from "./pages/home/home.component";
import { HomeComponentsModule } from "./components/components.module";
import { NgIf, NgTemplateOutlet } from "@angular/common";
import { GoogleSigninButtonModule } from "@abacritt/angularx-social-login";
import { BlogComponent } from "./pages/blog/blog.component";
import { ResponseTimeBlogComponent } from "./pages/blog/blogs/response-time/response-time-blog.component";
import { YelpBotBlogComponent } from "./pages/blog/blogs/yelp-bot/yelp-bot-blog.component";
import { ComponentsModule } from "../../components/components.module";
import { ContactUsDialogComponent } from "./pages/contact-us-dialog/contact-us-dialog.component";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MarkerComponent } from "./components/marker/marker.component";
import { CounterComponent } from "./components/counter/counter.component";
import { AnimationComponent } from "./components/animation/animation.component";

const components = [
  HomeComponent,
  BlogComponent,
  YelpBotBlogComponent,
  ResponseTimeBlogComponent,
  ContactUsDialogComponent
];

@NgModule({
  imports: [
    HomeComponentsModule,
    ComponentsModule,
    NgTemplateOutlet,
    GoogleSigninButtonModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    NgIf,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    MarkerComponent,
    CounterComponent,
    AnimationComponent
  ],
  declarations: components,
  exports: components
})
export class MarketingModule {}
