import { Regexes } from "./regexes";

export class TextHelper {
  static chars = {
    inv2: "\u{180E}",
    inv1: "\u{200B}",
    inv3: "\u{2800}"
  };

  static findPhoneNumberInText = str => {
    if (str) {
      return str
        .replace(/[^a-zA-Z0-9/]/g, "") // remove none numbers and letters
        .split(/[A-Za-z]+/) // split to numbers groups
        .filter(x => x) // remove empty
        .find(x => x.length === 10 || x.length === 11);
    } else {
      console.info("STR IS EMPTY");
    }
  };

  static validateEmail = str => {
    return str.match(Regexes.emailRegex);
  };

  static YelpCustomerNoLongerPursuingJob = str => {
    return (
      str.toLowerCase().startsWith("sorry, i no longer need this service") ||
      str.toLowerCase() === "Not interested" ||
      str.toLowerCase().includes("Automatic message:") ||
      str.match(Regexes.YelpCustomerNoLongerPursuingJob) ||
      str.match(Regexes.YelpCustomerBookedSomeoneElse)
    );
  };

  static findZipCodeInText = str => {
    let zip = str.match(/\n\d{5}$/)?.[0]?.substring(1);
    if (zip) {
      return str.match(/\n\d{5}$/)?.[0]?.substring(1);
    }

    zip = str.match(/In which ZIP code.*\?\n\d{5}/)?.[0]?.substring(1);
    if (zip) {
      return str.match(/In which ZIP code.*\?\n\d{5}/)?.[0]?.substring(53);
    }

    const locationStrs = ["Enter ZIP code\n", "Enter ZIP code "];
    const firstRes = locationStrs.map(locationStr => {
      const idx = str.indexOf(locationStr);
      return idx === -1 ? -1 : idx + locationStr.length;
    });
    const locIdx = firstRes.find(x => x > -1);
    if (locIdx && locIdx > -1) {
      return str.substr(locIdx, 5);
    }
  };

  static findEmailInText = text => {
    if (text) {
      const matches = text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
      return matches ? matches[0] : null;
    } else {
      console.info("STR IS EMPTY");
    }
  };
}
