import { Component, ViewChild } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";

import { ConnectionService } from "../../Services/connection.service";
import { SessionService } from "../../Services/session.service";

import { AllUserPickerComponent, MinimalUser } from "src/app/components/all-user-picker/all-user-picker.component";

@UntilDestroy()
@Component({
  selector: "app-super-admin",
  templateUrl: "./superAdmin.component.html",
  styleUrls: ["./superAdmin.component.scss"]
})
export class SuperAdminComponent {
  @ViewChild(AllUserPickerComponent) userPicker: AllUserPickerComponent;

  public isLoading = false;
  public selectedUser: MinimalUser | null = null;
  public filledTimezones = 0;
  public minutes = 60;
  public whatsappMessage = "";

  constructor(
    public sessionService: SessionService,
    private connectionService: ConnectionService
  ) {}

  refreshUserList() {
    if (this.userPicker) {
      this.userPicker.refreshUsers();
    }
  }

  public handleUserSelected(user: any): void {
    this.selectedUser = user;
  }

  async changeForceTrialForUser(user: MinimalUser, enable: boolean) {
    if (!user) {
      return;
    }

    await this.connectionService.post("superAdmin/changeForceTrialForUser", { userId: user._id, enable });

    this.refreshUserList();

    this.selectedUser = null;
  }

  redoLeads() {
    this.connectionService.post("superAdmin/redoLeads", {
      minutes: this.minutes
    });
  }

  billingId;
  syncBilling(id) {
    const body = id ? { id } : {};
    this.connectionService.post("superAdmin/syncBilling", body);
  }

  takeSnapshot() {
    this.connectionService.post("superAdmin/takeSnapshot");
  }

  syncYelpWebhookSources() {
    this.connectionService.post("superAdmin/syncYelpWebhooks");
  }

  empty1() {
    this.connectionService.post("superAdmin/empty1");
  }

  async fillTimezone() {
    this.isLoading = true;
    const result = await this.connectionService.post("operation-config/superAdmin/fillTimezone");
    this.filledTimezones = result.updatedCount;
    this.isLoading = false;
  }

  fakeAccepted() {
    this.connectionService.post(
      "accepted?response_type=code&scope=leads+r2r_get_businesses+r2r_business_owner&state=U2FsdGVkX18J9e/wgndQNHKSoL4K1qIedgyBVqW9aDXzhRu7rXo3SQ6AwocSg0UhxdZQJbccXCXOjwIxdyf8VQ==",
      "",
      "get"
    );
  }

  followUps() {
    this.connectionService.post("handle_followups", "");
  }
}
