import { Injectable } from "@angular/core";
import { ConnectionService } from "./connection.service";

@Injectable({
  providedIn: "root"
})
export class SourceService {
  constructor(private connectionService: ConnectionService) {}

  async getInvite(businessId) {
    return await this.connectionService.post("yelpLocations/invite", {
      businessId
    });
  }

  async getWhatsapp(sourceId: string) {
    return await this.connectionService.post("yelpLocations/whatsapp", {
      sourceId
    });
  }

  async update(sourceId: any) {
    return await this.connectionService.post("yelpLocations/update", {
      sourceId
    });
  }

  async refreshLocationWithToken(yelpToken) {
    return await this.connectionService.post("yelpLocations/refreshWithToken", {
      yelpToken
    });
  }
}
