import { Component } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { DataService } from "../../Services/data.service";
import { BillingService } from "../../Services/billing.service";
import { differenceBy } from "lodash";
import { UsageReport } from "../../../../../server/src/db/classes/billing";
import { SessionService } from "../../Services/session.service";

@UntilDestroy()
@Component({
  selector: "app-billing",
  templateUrl: "./activity-log.component.html",
  styleUrls: ["./activity-log.component.scss"]
})
export class ActivityLogComponent {
  sources = [];
  ready = false;
  displayedColumns = ["name", "hours", "price", "actions"];
  reports: {
    turnedOn: boolean;
    sources: any[];
    timestamp: any;
    ownerEmail: any;
  }[] = [];

  constructor(
    private billingService: BillingService,
    public sessionService: SessionService,
    public dataService: DataService
  ) {
    this.refresh();
  }

  private refresh = async () => {
    this.reports = [];
    const reports: UsageReport | any[] = await this.billingService.getUsageReports();

    for (let i = 0; i < reports?.length; i++) {
      let pre: UsageReport | any = reports[i - 1] || [];
      const cur: UsageReport | any = reports[i];
      if (pre.ownerEmail !== cur.ownerEmail) {
        pre = [];
      }
      const addedToCur = differenceBy(cur.activeSources, pre.activeSources);
      const removedFromCur = differenceBy(pre.activeSources, cur.activeSources);

      if (removedFromCur.length > 0) {
        this.reports.push({
          turnedOn: false,
          sources: removedFromCur,
          timestamp: cur.timestamp,
          ownerEmail: cur.ownerEmail
        });
      }

      if (addedToCur.length > 0) {
        this.reports.push({
          turnedOn: true,
          sources: addedToCur,
          timestamp: cur.timestamp,
          ownerEmail: cur.ownerEmail
        });
      }
    }
    this.reports?.sort((a, b) => {
      const timestampA: any = new Date(a.timestamp);
      const timestampB: any = new Date(b.timestamp);
      return timestampB - timestampA;
    });
    this.ready = true;
  };
}
