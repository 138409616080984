import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Source } from "../../../../../server/src/db/classes/source";
import { SourceService } from "../../Services/source.service";
import { SessionService } from "../../Services/session.service";
import { SourcesToken } from "../../../../../server/src/db/classes/sources-token";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Business } from "../../../../../server/src/db/classes/business";
import { DataService } from "../../Services/data.service";
import { BillingService } from "../../Services/billing.service";
import { NzDialogService } from "../../utils/services/nz-dialog.service";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { cloneDeep } from "lodash";
import { BusinessService } from "../../Services/business.service";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { StringConstants } from "../../../../../server/src/helpers/string-constants";
import ArrayHelpers from "../../../../../server/src/helpers/array-helpers";

export interface DialogData {
  source: Source;
  tokens: SourcesToken[];
  allBusinessesFlat: (Business | any)[];
  allBusinessesFlatById: { [key: string]: Business | any };
}

@UntilDestroy()
@Component({
  selector: "source-dialog",
  templateUrl: "./source-dialog.component.html",
  styleUrls: ["./source-dialog.component.scss"]
})
export class SourceDialogComponent implements OnInit {
  savingInProgress = false;
  originalSource: Source;
  businessSearchControl = new FormControl();
  filteredBusinesses: Observable<Business[]>;
  filteredBusinessList: Business[] = [];

  constructor(
    private sourceService: SourceService,
    public sessionService: SessionService,
    public dataService: DataService,
    public businessService: BusinessService,
    public nzDialogService: NzDialogService,
    private billingService: BillingService,
    public dialogRef: MatDialogRef<SourceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.originalSource = cloneDeep(this.data.source);
  }

  ngOnInit() {
    if (this.data.source.businessId$ && this.dataService.allBusinessesHashedById$[this.data.source.businessId$.toString()]) {
      const selectedBusiness = this.dataService.allBusinessesHashedById$[this.data.source.businessId$.toString()];
      this.businessSearchControl.setValue(selectedBusiness);
    }
    
    this.filteredBusinesses = this.businessSearchControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filterBusinesses(name) : this.filteredBusinessList.slice();
      })
    );
    
    this.dataService.allBusinessesList$.pipe(untilDestroyed(this)).subscribe(businesses => {
      this.filteredBusinessList = businesses.filter(x => x._id !== StringConstants.AllBusinessesId)
                                           .sort(ArrayHelpers.SortAlphabetically());
      
      if (this.data.source.businessId$ && this.dataService.allBusinessesHashedById$[this.data.source.businessId$.toString()]) {
        const selectedBusiness = this.dataService.allBusinessesHashedById$[this.data.source.businessId$.toString()];
        this.businessSearchControl.setValue(selectedBusiness);
      }
    });
    
    this.dataService.allBusinessesHashedById$.pipe(untilDestroyed(this)).subscribe(businessesMap => {
      if (this.data.source.businessId$ && businessesMap[this.data.source.businessId$.toString()]) {
        const selectedBusiness = businessesMap[this.data.source.businessId$.toString()];
        this.businessSearchControl.setValue(selectedBusiness);
      }
    });
  }

  encodeURIComponent = text => {
    return encodeURIComponent(text);
  };

  async save(source: Source) {
    const selectedBusiness = this.businessSearchControl.value;
    if (selectedBusiness && selectedBusiness._id) {
      source.businessId$ = selectedBusiness._id;
    }

    await this.normalizeSourceName(source);
    this.savingInProgress = true;
    try {
      await this.updateSource(source);
    } catch (e) {
      await this.handleError(e, source);
    } finally {
      this.savingInProgress = false;
    }
  }

  private async normalizeSourceName(source: Source) {
    if (source.name.trim() === "") {
      source.name = source.yelpLocationInfo.name;
    }
  }

  private async updateSource(source: Source) {
    await this.sourceService.update(source);
    this.dialogRef.close(source);
  }

  private async handleError(error: any, source: Source) {
    console.error(error);
    switch (error.errorCode) {
      case "no_billing_for_user":
        await this.billingService.promptForTrial(this.data.source);
        break;
      case "billing_not_good":
      case "self_billing_not_good":
        await this.handleBillingIssue(error, source);
        break;
      default:
        // handle other error types or rethrow
        break;
    }
  }

  private async handleBillingIssue(error: any, source: Source) {
    const selfBilling = error.errorCode === "self_billing_not_good";
    const dialogRef = this.nzDialogService.open(ConfirmDialogComponent, {
      maxWidth: 600,
      data: {
        title: "Cannot activate Source",
        mainText: error.description,
        hideConfirm: !selfBilling,
        action: "Fix issue"
      }
    });
    const confirm = await dialogRef.afterClosed().pipe(untilDestroyed(this)).toPromise();
    if (confirm) {
      await this.billingService.open(source._id);
    }
  }

  displayFn = (business: Business): string => {
    return business && business.name ? business.name : '';
  }
  
  private _filterBusinesses(value: string): Business[] {
    const filterValue = value.toLowerCase();
    return this.filteredBusinessList.filter(business => 
      business.name.toLowerCase().includes(filterValue));
  }
  
  businessSelected(event: MatAutocompleteSelectedEvent) {
    const selectedBusiness = event.option.value;
    this.data.source.businessId$ = selectedBusiness._id;
  }
}
