import { Component, EventEmitter, Input, Output } from "@angular/core";
import { YelpTimeUnit } from "../../../../../server/src/db/classes-helpers/yelpConfig";

@Component({
  selector: "app-time-input",
  templateUrl: "./time-input.component.html",
  styleUrl: "./time-input.component.scss"
})
export class TimeInputComponent {
  @Output() timeConfigChanged: EventEmitter<any> = new EventEmitter();

  @Input() minutes: number = 1;
  @Input() timeUnit: string = YelpTimeUnit.Minutes;
  @Input() isGlobalConfigApplied: boolean = false;

  protected yelpTimeUnit = YelpTimeUnit;
  @Input() timeUnitTypes: any[] = Object.values(YelpTimeUnit);
  @Input() max: any;

  public displayValue = 1;

  conversionFactors = {
    [YelpTimeUnit.Minutes]: 1,
    [YelpTimeUnit.Hours]: 60,
    [YelpTimeUnit.Days]: 1440
  };

  ngAfterViewInit() {
    this.displayValue = this.minutes / this.conversionFactors[this.timeUnit];
  }

  public editTime(): void {
    const valueToMinutes = this.displayValue * this.conversionFactors[this.timeUnit];

    const timeConfig = {
      minutes: valueToMinutes,
      type: this.timeUnit
    };

    this.timeConfigChanged.emit(timeConfig);
  }
}
