import { Component, HostListener, Inject } from "@angular/core";
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LoginService } from "../../../Services/login.service";
import { DOCUMENT } from "@angular/common";
import { trialDays } from "../../../../../../server/src/modules/billing/statics";
import { SessionService } from "../../../Services/session.service";
import { NzDialogService } from "../../../utils/services/nz-dialog.service";
import { ContactUsDialogComponent } from "../contact-us-dialog/contact-us-dialog.component";
import { LogoService } from "../../../Services/logo-service";
import { DemoDialogComponent } from "src/app/dialogs/demo-dialog/demo-dialog.component";
import { ConfirmDialogComponent } from "../../../dialogs/confirm-dialog/confirm-dialog.component";

@UntilDestroy()
@Component({
  selector: "app-home",
  templateUrl: "home.component.html",
  styleUrls: ["home.component.scss"]
})
export class HomeComponent {
  menuIsOpen = false;
  year = new Date().getFullYear();
  scrolledTop = true;
  mobileQuery = false;
  mediumQuery = false;
  trialDays = trialDays;

  constructor(
    private loginService: LoginService,
    public sessionService: SessionService,
    public logoService: LogoService,
    @Inject(DOCUMENT) public document: Document,
    private nzDialogService: NzDialogService,
    private socialAuthService: SocialAuthService
  ) {
    this.checkSize();
  }

  ngOnInit() {
    this.socialAuthService.authState.pipe(untilDestroyed(this)).subscribe(async socialUser => {
      this.checkScroll();
      if (socialUser) {
        try {
          await this.loginService.loginWithGoogle(socialUser);
        } catch (e) {}
      }
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.checkSize();
  }

  @HostListener("window:scroll", ["$event"]) // for window scroll events
  onScroll(event) {
    this.checkScroll();
  }

  private checkScroll() {
    this.scrolledTop = this.mediumQuery ? false : this.document.body.parentElement.scrollTop < 1;
  }

  private checkSize() {
    this.mobileQuery = document.body.clientWidth <= 600;
    this.mediumQuery = document.body.clientWidth <= 1280;
    this.checkScroll();
  }

  contactUs() {
    this.nzDialogService.open(ContactUsDialogComponent, {
      maxWidth: 600
    });
  }

  openRequestDemo() {
    this.nzDialogService
      .open(DemoDialogComponent, {
        maxWidth: 400
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result) {
          this.nzDialogService.open(ConfirmDialogComponent, {
            maxWidth: 500,
            data: {
              title: `Demo Request Confirmed`,
              mainText: `Your demo request has been successfully sent! \nWe will contact you shortly to schedule your session. \n\nThank you for your interest.`,
              action: "Close",
              hideCancel: true
            }
          });
        }
      });
  }

  callNow($event: MouseEvent) {
    const isMobile = ContactUsDialogComponent.detectMobile();
    if (!isMobile) {
      $event.preventDefault();
      this.contactUs();
    }
  }
}
