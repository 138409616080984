import { NgModule } from "@angular/core";
import { MatListModule } from "@angular/material/list";
import { AutofocusDirective } from "./auto-focus.directive";
import { LinkComponent } from "./link/link.component";
import { TextDividerComponent } from "./text-divider/text-divider.component";
import { NzLabelDirective } from "./nz-label.directive";
import { DynamicLogoComponent } from "../app/marketing/pages/dynamic-logo/dynamic-logo.component";

@NgModule({
  declarations: [TextDividerComponent, LinkComponent, AutofocusDirective, NzLabelDirective, DynamicLogoComponent],
  imports: [MatListModule],
  exports: [TextDividerComponent, DynamicLogoComponent, LinkComponent, AutofocusDirective, NzLabelDirective],
  providers: []
})
export class ComponentsModule {}
